import React, { createContext, useContext } from "react";
import constant, {
  TaskStatus,
  TemplateStatus,
  ToastType,
} from "../helper/constant";
import useAIModelReducer from "../reducers/ai-model.reducer";
import * as AIModelService from "../service/ai-model.service";
import * as ACTION from "../reducers/types";
import { getModelList } from "../service/ai-model.service";
import { useAuthContext } from "./auth.context";
import { useToasts } from "react-toast-notifications";
import {
  dataURLToBlob,
  getUUID,
  getUniqueModelIds,
  isEmptyObject,
} from "../helper/helper";
import { useAppContext } from "./app.context";

const AIModelContext = createContext();

const AIModelProvider = ({ children }) => {
  const { showAlert } = useAppContext();
  const { state, dispatch } = useAIModelReducer();
  const { addToast } = useToasts();

  /** AI Worfflow functions */
  const getModels = (id, cb) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    dispatch({ type: ACTION.AI_STRATEGY_RESET_MODEL_STATUS });
    getModelList(id)
      .then((result) => {
        if (result && result.length) {
          const { template_JSON } = result[0];
          const list = template_JSON || [];
          dispatch({
            type: ACTION.AI_STRATEGY_SET_ACTIVE_TEMPLATE,
            payload: { ...result[0] },
          });
          dispatch({
            type: ACTION.AI_STRATEGY_SET_MODELS,
            payload: list.length ? list : [{ id: getUUID() }],
          });
          if (cb) cb(result[0]);
        }
      })
      .catch((ex) => handleError(ex?.message))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const getDepartments = () => {
    const { departments, activeTemplate } = state;
    const { industryId } = activeTemplate;
    if (industryId && (!departments || !departments.length))
      AIModelService.getDepartmentList({ industryId })
        .then((r) => {
          if (r && r.length)
            dispatch({ type: ACTION.AI_STRATEGY_GET_DEPARTMENTS, payload: r });
        })
        .catch((ex) => handleError(ex?.message));
  };

  const getTasks = (departmentName) => {
    dispatch({ type: ACTION.AI_STRATEGY_GET_TASKS, payload: [] });
    if (departmentName) {
      AIModelService.getTaskListForDepartment({ departmentName })
        .then((r) => {
          dispatch({
            type: ACTION.AI_STRATEGY_GET_TASKS,
            payload: [...r] || [],
          });
        })
        .catch((ex) => handleError(ex?.message))
        .finally(() =>
          dispatch({ type: ACTION.UPDATE_LOADER, payload: false })
        );
    }
  };

  const saveDepartment = (data, cb) => {
    const list = [...state.models].map((zz) => {
      if (zz.id === data.id) return data;
      return zz;
    });
    const payload = {
      ...state.activeTemplate,
      template_JSON: list,
    };
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    AIModelService.updateExistingTemplate(payload)
      .then((result) => {
        cb();
        dispatch({ type: ACTION.AI_STRATEGY_SAVE_MODEL, payload: list });
      })
      .catch((ex) => handleError(ex?.message))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const addModel = (index) => {
    const { models } = state;
    const model = { id: getUUID() };
    if (models.length < constant.maxModelLimit) {
      const newArr = [...models];
      newArr.splice(index + 1, 0, model);
      dispatch({ type: ACTION.AI_STRATEGY_ADD_MODEL, payload: newArr });
    } else console.log("Max limit reached");
  };

  const deleteModel = (id) => {
    const { models } = state;
    const filteredList = [...models].filter((z) => z.id !== id);
    if (filteredList.length === 0) {
      const id = getUUID();
      filteredList.push({ id, name: id });
    }
    const payload = {
      ...state.activeTemplate,
      template_JSON: filteredList,
    };
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    AIModelService.updateExistingTemplate(payload)
      .then((result) => {
        dispatch({
          type: ACTION.AI_STRATEGY_DELETE_MODEL,
          payload: filteredList,
        });
      })
      .catch((ex) => handleError(ex?.message))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const onSelectModel = (data, isTask) => {
    if (isTask) {
      let formValue = {};
      let { modelId, model_name, parentId, taskId } = data;
      const list = state.aiDataFormValues.filter((zz) => zz.id === taskId);
      if (list && list.length)
        formValue = { ...list[0], modelId, model_name, parentId, taskId };
      else formValue = { id: taskId, model_name, modelId, parentId };
      dispatch({
        type: ACTION.AI_STRATEGY_SET_AI_MODEL_ON_SELECT_MODEL,
        payload: formValue,
      });
    } else {
      dispatch({ type: ACTION.AI_STRATEGY_ON_SELECT_MODEL, payload: data });
    }
  };

  const switchToSavedModel = (formValue) => {
    dispatch({
      type: ACTION.AI_STRATEGY_SET_AI_MODEL_ON_SELECT_MODEL,
      payload: formValue,
    });
  };

  const hideRightMenu = () =>
    dispatch({ type: ACTION.AI_STRATEGY_CLOSE_DROPDOWN_FORM });

  const updateRightMenu = (payload) =>
    dispatch({ type: ACTION.UPDATE_RIGHT_MENU, payload });

  const showModels = () => dispatch({ type: ACTION.AI_STRATEGY_SHOW_MODELS });

  /** Template functions */
  const { userInfo } = useAuthContext();

  const getTemplates = () => {
    const {
      org: { id },
    } = userInfo;
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    AIModelService.getTemplateList({ org_id: id })
      .then((list) => {
        let autoList = [];
        if (list.length) {
          list = list.filter((a) => a.active && a.active === 1);
          autoList = list.filter((a) =>
            a.template_name?.toLowerCase().includes("auto")
          );
          list.sort((b, a) => a.status?.localeCompare(b.status));
        }
        dispatch({
          type: ACTION.AI_STRATEGY_GET_TEMPLATES_SUCCESS,
          payload: { list, disableAutoCreate: autoList.length } || [],
        });
      })
      .catch((ex) => {
        handleError(ex?.message, true);
      })
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };
  const updateTemplateName = (name) => {
    dispatch({ type: ACTION.AI_STRATEGY_UPDATE_TEMPLATE_NAME, payload: name });
  };

  const createTemplateWithoutData = (data, cb) => {
    // const payload = { template_name, template_JSON: [], OrgId: id };
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    AIModelService.createNewTemplateWithoutData(data)
      .then((r) => {
        if (r) {
          dispatch({
            type: ACTION.AI_STRATEGY_SET_ACTIVE_TEMP_ID,
            payload: r.id,
          });
          cb(r.id);
        }
      })
      .catch((ex) => {
        handleError(ex?.message);
      })
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const createTemplateWithData = (data, cb) => {
    // const payload = { template_name, template_JSON: [], OrgId: id };

    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    AIModelService.createNewTemplateWithData(data)
      .then((r) => {
        if (r) {
          dispatch({
            type: ACTION.AI_STRATEGY_SET_ACTIVE_TEMP_ID,
            payload: r.id,
          });
          cb(r.id);
        }
      })
      .catch((ex) => {
        handleError(ex?.message);
      })
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const updateTemplate = (id, modelList) => {
    const { template_name } = state;
    const payload = {
      template_name,
      id,
      template_JSON: modelList || state.models,
    };
    AIModelService.updateExistingTemplate(payload)
      .then((r) => { })
      .catch((ex) => {
        handleError(ex?.message);
      });
  };
  const deleteDraftTemplate = (id) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    AIModelService.deleteDraftTemplate(id)
      .then((r) => {
        const list = state.templates.filter((zz) => zz.id !== id);
        dispatch({
          type: ACTION.AI_STRATEGY_GET_TEMPLATES_SUCCESS,
          payload: { list },
        });
      })
      .catch((ex) => {
        handleError(ex?.message);
      })
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const setActiveTemplateId = (template) =>
    dispatch({
      type: ACTION.AI_STRATEGY_SET_ACTIVE_TEMP_ID,
      payload: template,
    });

  /** AI Value functions */
  const getAIValue = (tempId, cb) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    getModels(tempId, (data) => {
      if (data && data.template_JSON && data.template_JSON.length) {
        dispatch({
          type: ACTION.UPDATE_AI_STRATEGY_AI_VALUES,
          payload: data.template_JSON[0].metrics,
        });
      }
      dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
    });
  };

  const updateAIValueProfit = (data) =>
    dispatch({ type: ACTION.UPDATE_AI_STRATEGY_AI_VALUES, payload: data });

  const updateCalculate = (v) =>
    dispatch({ type: ACTION.AI_STRATEGY_CALCULATE_VALUE, payload: v });

  /** AI Roadmap functions */
  const getRoadmapDetails = (tempId) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    if (!state.models || state.models.length === 0)
      getModels(tempId, (data) => {
        getTimelineData(data.template_JSON);
      });
    else getTimelineData(state.models);
  };

  const getTimelineData = (data) => {
    const {
      org: { org_config },
    } = userInfo;
    const modelIds = getUniqueModelIds(data);
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    const promiseList = modelIds.map((modelId) =>
      AIModelService.getModelDetailForId(modelId)
    );
    Promise.all(promiseList)
      .then((data) => {
        const roadmap = data.map((item) => ({
          month: item.model_timeLine,
          valueAdd:
            org_config.revenue && item.model_contribution
              ? Number(org_config.revenue) *
              (Number(item.model_contribution) / 100)
              : 0,
          modelName: item.model_name,
          name: item.model_name,
          modelId: item.id,
        }));
        dispatch({
          type: ACTION.AI_STRATEGY_GET_AI_ROADMAP_SUCCESS,
          payload: roadmap,
        });
      })
      .catch((ex) => handleError(ex?.message))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  /** AI model functions */
  const getAIModel = (id) => {
    if (id)
      getModels(id, () => {
        dispatch({
          type: ACTION.AI_STRATEGY_SET_AI_DATA_STATUS,
          payload: true,
        });
      });
  };

  const saveAIModelForm = (data, cb) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    const id = data.get("id");
    const { aiDataFormValues } = state;
    const filtered = [...aiDataFormValues].filter((zz) => zz.id === id);
    AIModelService.saveTrainingModel(data, filtered.length)
      .then((r) => {
        showAlert("Data saved successfully.", ToastType.SUCCESS, false);
        let features_column = {};
        if (data.get("feature")) {
          const features = data.get("feature").split(",");
          features_column = {};
          features.forEach((item) => {
            features_column[item] = "auto";
          });
        } else {
          features_column = { NA: "auto" };
        }

        const formValue = {
          id: data.get("id"),
          templateId: data.get("templateId"),
          modelId: data.get("modelId"),
          features_column: JSON.stringify(features_column),
          target_column: data.get("target"),
          status: TaskStatus.NEW,
        };
        setAIModelFormData(formValue);
        if (cb) cb(formValue);
      })
      .catch((ex) => handleError(ex?.message))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const saveSocialModelForm = (data) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    const { aiDataFormValues } = state;
    const filtered = [...aiDataFormValues].filter((zz) => zz.id === data.id);
    AIModelService.saveSocialMediaModel(data, filtered.length)
      .then((r) => {
        showAlert("Data saved successfully.", ToastType.SUCCESS, false);
        data.status = data.status || "New";
        setAIModelFormData(data);
      })
      .catch((ex) => handleError(ex?.message))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const setAIModelFormData = (data) => {
    const { aiDataFormValues } = state;
    let list = [...aiDataFormValues].filter((item) => !isEmptyObject(item));
    const filtered = [...aiDataFormValues].filter((zz) => zz.id === data.id);
    if (filtered.length) {
      list = list.map((zz) => {
        if (zz.id === data.id) return data;
        return zz;
      });
    } else list.push(data);
    dispatch({
      type: ACTION.AI_STRATEGY_SET_AI_MODEL_SAVE_AI_DATA,
      payload: list,
    });
  };

  const setAllAIModelFormData = (list) => {
    dispatch({
      type: ACTION.AI_STRATEGY_SET_AI_MODEL_SAVE_AI_DATA,
      payload: list,
    });
  };

  const triggerModelProcess = (id, data, cb) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    const filedata = new FormData();
    if (data) {
      const imgBlob = dataURLToBlob(data);
      const imageId = getUUID();
      filedata.append("png", imgBlob, `${imageId}.png`);
    } else {
      const imageId = getUUID();
      filedata.append("png", new Blob(), `${imageId}.png`);
    }

    AIModelService.triggerModelProcess(id, filedata)
      .then((r) => {
        if (r && cb) {
          cb(r);
        }
      })
      .catch((ex) => handleError(ex))
      .finally((ex) =>
        dispatch({ type: ACTION.UPDATE_LOADER, payload: false })
      );
  };
  /** AI Data functions */
  const getAIData = (id) => {
    if (id)
      getModels(id, () => {
        dispatch({
          type: ACTION.AI_STRATEGY_SET_AI_DATA_STATUS,
          payload: true,
        });
      });

    dispatch({ type: ACTION.AI_STRATEGY_SET_AI_DATA_STATUS, payload: true });
  };

  /** AI Predict functions */
  const getAIPredictData = (id) => {
    if (id)
      getModels(id, () => {
        dispatch({
          type: ACTION.AI_STRATEGY_SET_AI_PREDICT_SHOW_STATUS,
          payload: true,
        });
      });
    dispatch({
      type: ACTION.AI_STRATEGY_SET_AI_PREDICT_SHOW_STATUS,
      payload: true,
    });
  };

  const updatePredictionModel = (task) =>
    dispatch({ type: ACTION.AI_PREDICT_UPDATE_MODEL, payload: { ...task } });

  const getAIPredictLookup = (id) => {
    if (id)
      AIModelService.getAIPredictLookup(id)
        .then((r) =>
          dispatch({
            type: ACTION.AI_STRATEGY_SET_AI_PREDICT_LOOKUP,
            payload: r,
          })
        )
        .catch((ex) => handleError(ex?.message));
  };

  /** Global functions */
  const handleError = (e, ignore) => {
    if (!ignore)
      addToast(e || constant.MESSAGES.COMMON_ERROR_MESSAGE, {
        appearance: "error",
        autoDismiss: false,
      });
  };

  const canNavigate = (template) => {
    const { activeTemplate } = state;
    if (template) return template.status !== TemplateStatus.SCHEDULED;
    if (activeTemplate)
      return activeTemplate.status !== TemplateStatus.SCHEDULED;
    return true;
  };

  const clearModelInfo = (cb) => {
    dispatch({ type: ACTION.CLEAR_MODEL_INFO });
    if (cb) cb();
  };

  const updateAutoCreate = (flag) =>
    dispatch({ type: ACTION.UPDATE_AUTO_CREATE_FLOW, payload: flag });

  const updateIsOverviewFirstTime = (flag) =>
    dispatch({ type: ACTION.UPDATE_OVERVIEW_FIRST_TIME, payload: flag });

  const setPredictionData = (data) =>
    dispatch({
      type: ACTION.AI_STRATEGY_UPDATE_AI_PREDICTION_DATA,
      payload: data,
    });

  return (
    <AIModelContext.Provider
      value={{
        ...state,
        addModel,
        deleteModel,
        onSelectModel,
        getDepartments,
        saveDepartment,
        getTasks,
        showModels,
        hideRightMenu,
        updateRightMenu,
        updateCalculate,
        getModels,
        getTemplates,
        setActiveTemplateId,
        createTemplateWithData,
        createTemplateWithoutData,
        updateTemplate,
        deleteDraftTemplate,
        updateTemplateName,
        getRoadmapDetails,
        getAIValue,
        getAIModel,
        saveAIModelForm,
        setAIModelFormData,
        saveSocialModelForm,
        getAIData,
        getAIPredictData,
        getAIPredictLookup,
        canNavigate,
        triggerModelProcess,
        clearModelInfo,
        updatePredictionModel,
        switchToSavedModel,
        updateAutoCreate,
        updateIsOverviewFirstTime,
        setPredictionData,
        setAllAIModelFormData,
        updateAIValueProfit,
      }}
    >
      {children}
    </AIModelContext.Provider>
  );
};

export const useAIModelContext = () => useContext(AIModelContext);
export { AIModelProvider, AIModelContext };
