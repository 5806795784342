import React, { useEffect, useState } from "react";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";
import { useReactFlow } from "@xyflow/react";
import { Form } from "react-bootstrap";
import CustomDropdown from "../../../common/CustomDropdown";
import { useAIModelContext } from "../../../../context/ai-model.context";
import constant, { InputTypes, ToastType } from "../../../../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { deleteScheduleWorkflowAPI } from "../../../../service/ai-model.service";
import { useAppContext } from "../../../../context/app.context";

const cronRegex = /^(\*|([0-5]?[0-9])|([0-5]?[0-9]\/[1-9][0-9]*))?(\s+(\*|([0-1]?[0-9]|2[0-3])|([0-1]?[0-9]|2[0-3]\/[1-9][0-9]*))?)?(\s+(\*|([1-9]|[12][0-9]|3[01])))?(\s+(\*|([1-9]|1[0-2]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))?(\s+(\*|([0-7]|SUN|MON|TUE|WED|THU|FRI|SAT)))?$/;


const InputNodeConfig = () => {
    const [forms, setForms] = useState({ inputType: '', llm: '', prompt: '', scrapping_url: '', fieldMissing: false, schedule: '' });
    const [error, setError] = useState('')
    const { setNodes } = useReactFlow();
    const { updateRightMenu } = useAIModelContext();
    const { showAlert, updateLoader } = useAppContext();
    const { selectedNode, getWorkflowInputTypes, inputTypes, llmList, getWorkflowLLMList, workflowEdit } = useAIWorkflowContext();

    useEffect(() => {
        getWorkflowInputTypes();
        getWorkflowLLMList();
    }, [])

    useEffect(() => {
        if (selectedNode && selectedNode.data) {
            setForms({
                inputType: selectedNode.data.inputType,
                llm: selectedNode.data.llm,
                prompt: selectedNode.data.prompt,
                scrapping_url: selectedNode.data.scrapping_url,
                fieldMissing: selectedNode.data.fieldMissing,
                schedule: selectedNode.data.schedule
            });
        }

    }, [selectedNode]);

    const renderInputType = () => {
        return (
            <div>
                <CustomDropdown
                    name="Input Type"
                    options={inputTypes}
                    field="value"
                    value={forms.inputType}
                    onChange={inputType => setForms({ ...forms, inputType })}
                />
            </div>
        )
    }



    const renderLLMType = () => {
        return (
            <div>
                <CustomDropdown
                    name="LLM Type"
                    options={llmList}
                    field="value"
                    value={forms.llm}
                    onChange={llm => setForms({ ...forms, llm })}
                />
            </div>
        )
    }

    const renderPrompt = () => {
        return (
            <div className="d-flex flex-column"
            >
                <div className="custom-dropdown-label text-blue">
                    <b>Prompt</b>
                </div>
                <textarea
                    rows={2}
                    style={{ minHeight: 80 }}
                    className={`form-control`}
                    placeholder="LLM prompt for better data sanity"
                    value={forms.prompt}
                    onChange={(e) => setForms({ ...forms, prompt: e.currentTarget.value })}
                />
            </div>
        )
    }

    const renderSchedular = () => {
        return (
            <div className="d-flex flex-column"
            >
                <div className="custom-dropdown-label text-blue">
                    <b>Schedule</b>
                </div>
                <input
                    className={`form-control ${error ? 'error-br' : ''}`}
                    placeholder="* * * * *"
                    value={forms.schedule}
                    onChange={(e) => setForms({ ...forms, schedule: e.currentTarget.value })}
                />
                <small className="hint pt-1">Please use cron format. Example for every 30 minutes (*/30 * * * *)</small>
            </div>
        )
    }

    const renderWebsiteURL = () => {
        return (
            <div className="d-flex justify-content-between align-items-center"
            >
                <input
                    className={`form-control`}
                    placeholder="Website URL"
                    value={forms.scrapping_url}
                    autoCorrect="false"
                    autoCapitalize="false"
                    autoComplete="no"
                    onChange={(e) => setForms({ ...forms, scrapping_url: e.currentTarget.value })}
                />
            </div>
        )
    }

    const renderFieldMissing = () => {
        return (
            <Form.Check
                type="checkbox"
                id='fieldMissing'
                label="Few fields missing"
                value={forms.fieldMissing}
                onChange={() => setForms({ ...forms, fieldMissing: !forms.fieldMissing })}
            />
        )
    }

    const handleValidation = () => {
        let isValid = true;
        if (!cronRegex.test(forms.schedule)) {
            setError(true);
            isValid = false;
        } else {
            setError(false)
        }
        return isValid
    }

    const onFormSubmit = e => {
        e.preventDefault();
        if (!handleValidation()) {
            const { id } = selectedNode;
            const input = inputTypes.filter(item => item.id === forms.inputType)[0];
            const llmItem = llmList.filter(item => item.id === forms.llm)[0];
            setNodes((nodes) => nodes.map((node) =>
                node.id === id
                    ? { ...node, data: { ...node.data, ...forms, input_type: input.value, url: input.endpoint, llm_type: llmItem?.model_type } }
                    : node
            ));
            updateRightMenu(false);
        }
    }

    const renderSaveButton = () => {
        return (
            <div className="form-group">
                <button
                    type="submit"
                    className="btn2 btn radious-0 w-100 f-13 text-uppercase py-2 font15 fontweight600">
                    save
                </button>
            </div>
        )
    }
    const renderConfigForm = () => {
        return (
            <form className="d-flex flex-column gap-2" onSubmit={onFormSubmit} noValidate>
                {renderInputType()}
                {(forms.inputType && forms.inputType !== InputTypes.ManualInput) &&
                    <>
                        {renderLLMType()}
                        {renderWebsiteURL()}
                        {renderPrompt()}
                        {renderSchedular()}
                        {renderFieldMissing()}
                    </>
                }
                {renderSaveButton()}
            </form>
        )
    }

    const onSheduelDelete = () => {
        updateLoader(true);
        deleteScheduleWorkflowAPI()
            .then((r) => showAlert(constant.MESSAGES.DELETED_MESSAGE, ToastType.SUCCESS))
            .catch((r) => showAlert(constant.MESSAGES.COMMON_ERROR_MESSAGE, ToastType.SUCCESS))
            .finally(() => updateLoader(false));
    }

    const renderConfigrationStatus = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-1">
                <p className="p-0 m-0">
                    <span className="text-black">Input Type :</span>
                    <span className="text-secondary"> {selectedNode.data.input_type}</span>
                </p>

                {
                    (selectedNode.data.input_type !== 'manual') &&
                    <>
                        <p className="p-0 m-0">
                            <span className="text-black">LLM Type :</span>
                            <span className="text-secondary"> {selectedNode.data.llm_type}</span>
                        </p>
                        <p className="p-0 m-0">
                            <span className="text-black">Scrapping URL :</span>
                            <a className="" href={selectedNode.data.scrapping_url} target="_blank" rel="noopener noreferrer"> {selectedNode.data.scrapping_url || '--'}</a>
                        </p>
                        <p className="p-0 m-0">
                            <span className="text-black">Prompt :</span>
                            <span className="text-secondary"> {selectedNode.data.prompt || '--'}</span>
                        </p>
                        {selectedNode.data.schedule &&
                            <p className="p-0 m-0 d-flex align-items-center justify-content-between">
                                <p className="p-0 m-0">
                                    <span className="text-black">Schedule :</span>
                                    <span className="text-secondary"> {selectedNode.data.schedule || '--'}</span>
                                </p>
                                <Link onClick={() => onSheduelDelete()}><FontAwesomeIcon icon={faTrashCan} className="text-danger" /></Link>
                            </p>
                        }
                        <p className="p-0 m-0">
                            <span className="text-black">Fied Missing :</span>
                            <span className="text-secondary"> {selectedNode.data.fieldMissing ? 'Yes' : 'No'}</span>
                        </p>
                    </>
                }
            </div>
        )
    }



    const render = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-3 pt-3">
                <h5>Input Node Configuration</h5>
                {workflowEdit ? renderConfigForm() : renderConfigrationStatus()}
            </div>
        )
    }
    return render();

}

export default InputNodeConfig