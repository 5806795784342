import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { AIModelProvider } from "./context/ai-model.context";
import { AIAskMeProvider } from "./context/ai-ask-me.context";
import { AppProvider } from "./context/app.context";
import { AuthProvider } from "./context/auth.context";
import Navigator from "./navigation/Navigator";
import constant from "./helper/constant";
import { ScreenSizeProvider } from "./context/mobile-layout.context";
import { ReactFlowProvider } from "@xyflow/react";
import { WorkflowProvider } from "./context/ai-workflow.context";

const App = () => {
  const render = () => {
    return (
      <ScreenSizeProvider>
        <ToastProvider {...constant.TOAST_CONFIG}>
          <AppProvider>
            <AuthProvider>
              <AIModelProvider>
                <BrowserRouter>
                  <AIAskMeProvider>
                    <ReactFlowProvider>
                      <WorkflowProvider>
                        <Navigator />
                      </WorkflowProvider>
                    </ReactFlowProvider>
                  </AIAskMeProvider>
                </BrowserRouter>
              </AIModelProvider>
            </AuthProvider>
          </AppProvider>
        </ToastProvider>
      </ScreenSizeProvider>
    );
  };

  return render();
};

export default App;
