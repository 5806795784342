import React, { useEffect, useState } from "react";
import {
  getImagePrediction,
  postImagePrediction,
} from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useParams } from "react-router-dom";
import CustomLoader from "../../../../common/CustomLoader";
import { ToastType } from "../../../../../helper/constant";
import ImagePredictionGrid from "./ImagePredictionGrid";
import ImageUploadForm from "../../../../common/ImageUploadForm";

const ImageClassificationAI = () => {
  const [imageList, setImageList] = useState([]);
  const { updateLoader, isLoading, showAlert } = useAppContext();
  const params = useParams();
  const { selectedPredictionModel } = useAIModelContext();

  useEffect(() => {
    getImagePredictionList();
  }, []);

  const getImagePredictionList = () => {
    const { taskId, templateId = params.id } = selectedPredictionModel;
    if (templateId) {
      updateLoader(true);
      getImagePrediction(templateId, taskId)
        .then((result) => {
          if (result && result.length) setImageList(result);
        })
        .catch(console.log)
        .finally(() => updateLoader(false));
    }
  };
  const onSubmit = (image) => {
    if (image) {
      const { taskId, templateId = params.id } = selectedPredictionModel;
      updateLoader(true);
      const formData = new FormData();
      formData.append("csv", image);
      postImagePrediction(templateId, taskId, formData)
        .then((result) => {
          getImagePredictionList();
        })
        .catch((ex) => showAlert(JSON.stringify(ex), ToastType.ERROR))
        .finally(() => updateLoader(false));
    }
  };

  const renderImagePredictionGrid = () => {
    return <ImagePredictionGrid imageList={imageList} />;
  };

  const render = () => {
    return (
      <>
        {isLoading && (
          <div className="full-mask d-flex justify-content-center">
            <CustomLoader masked />
          </div>
        )}
        <div className="d-flex flex-column gap-4">
          <ImageUploadForm onSubmit={onSubmit} />
          {renderImagePredictionGrid()}
        </div>
      </>
    );
  };
  return render();
};

export default ImageClassificationAI;
