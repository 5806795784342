import React, { useEffect, useState } from "react";
import { getWorkflowLogsAPI } from "../../../../service/ai-model.service";
import { useAppContext } from "../../../../context/app.context";
import constant, { ToastType } from "../../../../helper/constant";
import ModalComponent from "../../../common/ModalComponent";
import CustomLoader from '../../../common/CustomLoader'
import { formatDate } from "../../../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

const WorkflowStatus = {
    SUCCEEDED: "SUCCEEDED",
    FAILED: 'FAILED'
};

const WorkflowLogs = ({ name, showModal, setShowModal }) => {
    const { showAlert } = useAppContext();
    const [loading, setLoading] = useState(false)
    const [workflowLogs, setWorkflowLogs] = useState({ data: [], error: null });

    useEffect(() => {
        setLoading(true);
        getWorkflowLogsAPI({ name })
            .then(result => {
                if (result.length) {
                    setWorkflowLogs({ data: result.slice(0, 15) });
                } else {
                    setWorkflowLogs({ data: [], error: 'No logs avaiable' });
                }
            })
            .catch((e) => showAlert(JSON.stringify(e), ToastType.ERROR))
            .finally(() => setLoading(false))
    }, [])

    const renderLogsColumns = () => {
        return (
            <thead>
                <tr>
                    <th scope="col"><p className="p-0 m-0">State</p></th>
                    <th scope="col"><p className="p-0 m-0">Start Time</p></th>
                    <th scope="col"><p className="p-0 m-0">End Time</p></th>
                    <th scope="col"><p className="p-0 m-0">Duration(s)</p></th>
                </tr>
            </thead>
        );
    };


    const renderTableContent = () => {
        const { data } = workflowLogs;
        return (
            <tbody>
                {
                    data.map((item, rowIndex) => {
                        const { state, status: { currentSteps }, startTime, endTime, duration } = item;
                        return (
                            <tr key={rowIndex}>
                                <td className=''>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon
                                            icon={state === WorkflowStatus.SUCCEEDED ? faCheckCircle : faXmarkCircle}
                                            className={state === WorkflowStatus.SUCCEEDED ? 'text-success' : 'text-danger'}
                                        />
                                        <p className="mx-1 workflow-status">{currentSteps[0].step}</p>
                                        <p className="m-0 p-0 text-secondary">{state}</p>
                                    </div>
                                </td>
                                <td className=''><p className="m-0 p-0">{formatDate(startTime.seconds * constant.OneSecond, constant.fulldateTimeFormat)}</p></td>
                                <td className=''><p className="m-0 p-0">{formatDate(endTime.seconds * constant.OneSecond, constant.fulldateTimeFormat)}</p></td>
                                <td className=''><p className="m-0 p-0">{Number(Number(duration.seconds) + (duration.nanos / 1e9)).toFixed(3)}</p></td>
                            </tr>
                        );
                    })
                }
            </tbody>
        )
    }

    const renderLogs = () => {
        return (
            <table className="workflowlogs table align-middle">
                {renderLogsColumns()}
                {renderTableContent()}
            </table>
        );
    }

    const renderWorkflowLogsTable = () => {
        if (workflowLogs.data.length) {
            return (
                <div className="workflowlogs-modal-content">
                    {renderLogs()}
                </div>
            )
        }
        return <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
            <p>{workflowLogs.error}</p>
        </div>
    }
    const render = () => {
        return (
            <>
                {loading && <div className="d-flex align-items-center justify-content-center"><CustomLoader /></div>}
                {renderWorkflowLogsTable()}
            </>
        )
    }

    return (
        <>
            <ModalComponent
                full
                title="Workflow Logs"
                show={showModal}
                handleClose={setShowModal}
                classes={'workflowlogs-modal w-80'}
                height='70vh'
            >
                {render()}
            </ModalComponent>
        </>
    );
}

export default WorkflowLogs;