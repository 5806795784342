import React from 'react';
import { useAIModelContext } from '../../../../context/ai-model.context';
import { useAIWorkflowContext } from '../../../../context/ai-workflow.context';
import { Handle } from '@xyflow/react';
import { AvailableNodes, NodeType, TemplateStatus } from '../../../../helper/constant';
import { renderNodeIcon } from '../../../../helper/helper';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OutputNode = ({ data, id, setNodes, workflow }) => {
    const { showRightMenu, updateRightMenu } = useAIModelContext();
    const { updateSelecteNode, selectedNode } = useAIWorkflowContext();


    const handleNodeClick = () => {
        updateRightMenu(true);
        updateSelecteNode({ id, data })
    };

    const renderNodeDetails = () => {
        if (data && data.target)
            return (
                <div className='p-2 node-details'>
                    <p className='p-0 m-0 text-black'>Target:<span className='text-secondary'>{data.target}</span></p>
                </div>
            )
    }

    const render = () => {
        if (data)
            return (
                <div className={`dndnode d-flex flex-column gap-1 ${NodeType.OutputNode}`} onClick={handleNodeClick}>
                    {data &&
                        <div className='p-2 m-0 node-title d-flex justify-content-between align-items-center' style={{ backgroundColor: AvailableNodes[2].color }}>
                            <div className='d-flex align-items-center'>
                                {renderNodeIcon(data.type, 25)}
                                <p className='p-0 m-0'>{data.label}</p>
                            </div>
                            {/* {workflow.status === TemplateStatus.COMPLETED && <FontAwesomeIcon icon={faCircleCheck} size='1x' color='green' />} */}
                        </div>
                    }
                    {renderNodeDetails()}
                    <Handle
                        type="target"
                        position="left"
                        style={{ background: '#555' }}
                        id={`${id}-target`}
                        isConnectable={true}
                    />
                </div>
            )
    }
    return render();
}

export default OutputNode