import {
  createNewOrgCloudStorage,
  createNewOrgDatabase,
  updateOrganization,
} from "../service/auth.service";
import constant, { COLORS, NodeType, TaskType } from "./constant";
import Moment from "moment";
import * as htmlToImage from "html-to-image";
import { v4 as uuidv4 } from "uuid";
import Papa from "papaparse";
import images from "./images";

export const stringToHslColor = (str, s, l) => {
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
export const getUUID = () => uuidv4();

export const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0]?.toUpperCase())
    .join("");
};
export const formatNumberInMillions = (value) => {
  const formater = Intl.NumberFormat("en", {
    notation: "standard",
    maximumFractionDigits: 0,
  });
  return formater.format(value);
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const renderNodeIcon = (type, width) => {
  switch (type) {
    case NodeType.AgentNode:
      return <img src={images.agentNode} alt={type} width={width || 'auto'} />
    case NodeType.OutputNode:
      return <img src={images.outputNode} alt={type} width={width || 'auto'} />
    default:
      return <img src={images.inputNode} alt={type} width={width || 'auto'} />
  }
}

export const hasNoEmptyObjects = (array) => {
  return array.every((item) => !isEmptyObject(item));
};

export const formatDate = (date, format = constant.dateFormat) => {
  return Moment(date || new Date()).format(format);
};

export const ageAgo = (date) => Moment(date).fromNow();

export const formatDateInDays = (date) => {
  const targetDate = Moment(date);
  return targetDate.fromNow();
};

export const formatToCurrency = (v, currency = constant.defLocale.currency) => {
  // return Number(v).toLocaleString(constant.defLocale.locale, { style: "currency", currency: currency, currencyDisplay: "symbol" });
  return `$ ${formatNumberInMillions(v)}`;
};

export const groupByPropertyOfArray = (data, key) => {
  return covertObjectToArary(
    data.reduce((result, currentValue) => {
      const groupKey = currentValue[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {})
  );
};

export const currencyDifference = (af, be) => {
  return formatToCurrency(Number(af) - Number(be));
};

export const sumOfObject = (data) => {
  return Object.values(data).reduce((acc, curr) => acc + curr, 0);
};

export const getUniqueModelIds = (list) => {
  const tasks = [];
  list.map((item) => {
    return tasks.push(...item.tasks);
  });
  return Array.from(
    tasks.reduce((set, obj) => {
      set.add(obj.modelId);
      return set;
    }, new Set())
  );
};

export const weekToMonth = (weeks) => {
  if (weeks && !Number.isNaN(weeks)) {
    return Math.round(weeks / 4.35);
  }
  return 0;
};

export const validateEmail = (email) => {
  // Regular expression for email validation
  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) || phoneRegex.test(email);
};

export const covertObjectToArary = (data) => {
  const list = [];
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const element = data[key];
      list.push({ key, value: element });
    }
  }
  return list;
};

export const validateCompanyName = (name) => {
  // Regular expression for email validation
  const companyRegex = /^[a-zA-Z0-9]+$/;
  return companyRegex.test(name);
};

export const validateOrgName = (name) => {
  // Regular expression for email validation
  const companyRegex = /^[a-zA-Z0-9 ]+$/;
  return companyRegex.test(name);
};

export const getValueInBetween = (value) => {
  let dyanamic =
    value * (constant.maxCylinderWidth - constant.minCylinderWidth) +
    constant.minCylinderWidth;
  return dyanamic;
};

// Log based divider calculation
const logBasedWidth = (value) => Math.log(value);
//export const getDividerLeft = (value) => roundOff(constant.minCylinderWidth + logBasedWidth(value));

// factor based divider calculation
const factorBasedWidth = (value) =>
  constant.modelScaleFactor * Math.sqrt(value / constant.modelDivisionFactor);
export const getDividerLeft = (value) =>
  roundOff(constant.minCylinderWidth + factorBasedWidth(value));

export const getPercentageOfWidth = (value, width) =>
  (parseFloat(value) / 100) * parseFloat(width);

export const calculatePercentage = (value, base) => {
  return (Number(value) / Number(base)) * 100;
};

export const getTotalOfProperty = (list, key) =>
  list.reduce((acc, current) => acc + parseInt(current[key] || 0, 10), 0);

export const roundOff = (value) => Math.round(value);

export const getTotalDynamicWidth = (list) => {
  return list.reduce((taskAcc, task) => {
    return taskAcc + getDividerLeft(task.expense);
  }, 0);
};

export const openWindow = (url, options = {}) => {
  const { target, width = 800, height = 600 } = options;
  return window.open(
    url,
    target || constant.AppTitle,
    `toolbar=0,status=0,width=${width},height=${height}`
  );
};

export const getDynamicColor = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

  r = color >> 16;
  g = (color >> 8) & 255;
  b = color & 255;

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) return "#333";

  return "#EEE";
};

export const getHtmlToImgData = (id = "ai-model-img") => {
  return new Promise((res, rej) => {
    const el = document.getElementById(id);
    if (el)
      htmlToImage
        .toPng(el)
        .then((dataUrl) => {
          res(dataUrl);
        })
        .catch((error) => {
          console.error("Error converting HTML to image:", error);
          rej(error);
        });
    else rej("No template found");
  });
};

export const dataURLToBlob = (dataURL) => {
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const byteCharacters = atob(parts[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const createNewTemplateData = (data) => {
  const newTemplate = { ...data, id: null, url: null };
  const parentId = getUUID();
  newTemplate.template_JSON = newTemplate.template_JSON.map((model) => {
    const tasks = model.tasks.map((task) => ({
      ...task,
      parentId,
      taskId: getUUID(),
    }));
    return {
      ...model,
      tasks,
      id: parentId,
    };
  });
  return newTemplate;
};

export const orgCreationBootstrap = async (data, cb) => {
  try {
    await createNewOrgDatabase();
    await createNewOrgCloudStorage();
    await updateOrganization(data.id, { active: true });
    if (cb) cb();
  } catch (e) {
    console.log(e);
  }
};

export const sendKeyToLast = (data, keyToMove) => {
  data = data.filter((zz) => !keyToMove.includes(zz));
  // Sort the remaining key-value pairs based on keys
  data.sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  //Push keyToMove
  data.push(...keyToMove);
  return data;
};

export const downloadCSV = (data, filename) => {
  try {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename || "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (ex) {
    console.log(ex);
  }
};

export const convertToArrayOfObjects = (data) => {
  return Object.entries(data).map(([key, value]) => ({ key, value }));
};

export const getHighestByParameter = (array, parameter) => {
  return array.reduce((prev, current) =>
    Number(prev[parameter]) > Number(current[parameter]) ? prev : current
  );
};
const arrayToCsv = (arrayOfObjects) => {
  if (arrayOfObjects.length === 0) {
    return "";
  }

  const header = Object.keys(arrayOfObjects[0]);
  const csvRows = [];

  // csvRows.push(header.join(','));

  arrayOfObjects.forEach((obj) => {
    const row = header.map((key) => {
      return obj[key];
    });
    csvRows.push(row.join(","));
  });
  return csvRows.join("\n");
};

export const generateCsvBlob = (arr) => {
  const csvString = arrayToCsv(arr);
  const blob = new Blob([csvString], { type: "text/csv" });
  return blob;
};

export const convertArrayToObjects = (arr) => {
  let obj = {};
  arr.forEach((item) => {
    obj[item.label] = item.value;
  });
  return obj;
};
export const getImageStoreHost = () => process.env.REACT_APP_IMAGE_HOST;

export const getCoordinates = (cord, properties) => {
  return {
    x1: parseInt(cord.x) / properties.newWidth,
    y1: parseInt(cord.y) / properties.newHeight,
    blank1: "",
    blank2: "",
    x2: (parseInt(cord.x) + parseInt(cord.width)) / properties.newWidth,
    y2: (parseInt(cord.y) + parseInt(cord.height)) / properties.newHeight,
    blank3: "",
    blank4: "",
  };
};
const exportHTML = (content, fileName) => {
  const sourceHTML = `<html xmlns:o='urn:schemas-microsoft-com:office:office' 
  xmlns:w='urn:schemas-microsoft-com:office:word'
  xmlns='http://www.w3.org/TR/REC-html40'>
  <head>
  <meta charset='utf-8'>
  <title>${fileName}</title>
  </head>
    <body>${content}</body>
  </html>`;

  const source = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8,${encodeURIComponent(sourceHTML)}`;
  var fileDownload = document.createElement("a");
  document.body.appendChild(fileDownload);
  fileDownload.href = source;
  fileDownload.download = `${fileName}.docx`;
  fileDownload.click();
  document.body.removeChild(fileDownload);
};
export const generateOverviewWord = (fileName) => {
  return new Promise((res, rej) => {
    try {
      let el1 = document.getElementById("BusinessOverview");
      let el2 = document.getElementById("InternalAnalysis");
      let el3 = document.getElementById("ExternalLandscape");
      let el4 = document.getElementById("AIRecommendations");
      const finalDoc = `
      <header className='doc-header'>AVATAR AI</header>
      <hr>
      <br>
      <h2>Business Overview (${fileName})</h2>
      <p className='preserve-line-breaks'${el1.innerHTML}</p><br>
      <h2>Internal Analysis</h2>
      <p className='preserve-line-breaks'${el2.innerHTML}</p><br>
      <h2>External Landscape</h2>
      <p className='preserve-line-breaks'${el3.innerHTML}</p><br>
      <h2>AI Recommendations</h2>
      <p className='preserve-line-breaks'>${el4.innerHTML}</p><br>
      <hr>
      <footer>End of document</footer>`;
      exportHTML(finalDoc, fileName);
      res();
    } catch (ex) {
      rej(ex);
    }
  });
};

export const getCalculateExpense = (type, task, revenue) => {
  switch (type) {
    case TaskType.EMPLOYEE:
      const { avgSalary, noOfEmpForTask } = task;
      if (avgSalary && noOfEmpForTask)
        return Number(avgSalary) * Number(noOfEmpForTask);
      break;
    case TaskType.INVENTORY:
      const { inventory } = task;
      return Number(inventory);
    case TaskType.CHURNRATE:
      const { churnrate } = task;
      return (Number(churnrate) / 100) * Number(revenue);
    case TaskType.DOWNTIME:
      const { downtime } = task;
      return (Number(downtime) / 100) * Number(revenue);
    default:
      return 0;
  }
};

export const getTimeStamp = () => Date.now();

export const convertToNumber = (num, decimal) =>
  Number(Number(num).toFixed(decimal || 1));

export const randomColor = () => {
  const randomIndex = Math.floor(Math.random() * COLORS.length);
  return COLORS[randomIndex];
};
