import React, { useState } from "react";
import ModalComponent from "../../../common/ModalComponent";
import { NodeType } from "../../../../helper/constant";

const WorkflowManualInput = ({ showModal, setShowModal, title, nodes, onExecute }) => {

    const renderCreateDynamicForm = (list) => {
        const data = {}
        list.map(item => {
            data[item] = '';
        });
        return <WorkFlowForm fields={list} defaultFormData={data} onSubmit={onExecute} />
    }
    const renderManualInput = () => {
        const inputNode = nodes.filter(item => item.type === NodeType.InputNode);
        if (inputNode.length) {
            return renderCreateDynamicForm(inputNode[0].data.input);
        }
        return <p>No input field available. Please connect with system administrator</p>
    };

    const render = () => {
        return (
            <ModalComponent
                full
                title="Workflow Manual Input"
                show={showModal}
                handleClose={setShowModal}
                classes={'w-50'}
            >
                {renderManualInput()}
            </ModalComponent>
        )
    }
    return render();
}

export default WorkflowManualInput

const WorkFlowForm = (props) => {
    const { fields, defaultFormData, onSubmit } = props;
    const [formData, setFormData] = useState(defaultFormData);
    const [form, setForm] = useState({ error: {} })

    const handleOnChange = (property, value) => {
        const data = { ...formData };
        data[property] = value;
        setFormData(data);
    };

    const handleValidation = () => {
        let formIsValid = true;
        let error = {};
        for (const k in formData) {
            if (!formData[k]) {
                formIsValid = false;
                error[k] = `${k} is required.`;
            }
        }
        setForm({ error });
        return formIsValid;
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            onSubmit({ ...formData });
        }
    }

    const renderFormList = () => {
        return fields.map((item, index) => {
            const formLabel = item.replaceAll('_', ' ');
            return (
                <div key={index} className="col-sm-12 col-lg-6 pb-2">
                    <label htmlFor={`workflow-form-input-${item}`} className="capitalize">{formLabel}</label>
                    <input
                        id={`workflow-form-input-${item}`}
                        type="text"
                        className={`form-control workflow-form-input ${form.error[item] ? "error-br" : ""}`}
                        value={formData[item]}
                        placeholder={formLabel}
                        onChange={(e) => handleOnChange(item, e.target.value)}
                    />
                </div>
            )
        })
    }

    const render = () => {
        return (
            <form noValidate onSubmit={onFormSubmit} className="d-flex flex-column gap-1">
                <h6 className="pb-2">Please fill all fields below to execute workflow</h6>
                <div className="row">
                    {renderFormList()}
                </div>
                {<button
                    type="submit"
                    className="btn2 btn radious-0 w-25 mt-2 f-13 text-uppercase py-2 font15fontweight600 align-self-center">
                    Execute Workflow
                </button>}
            </form>
        )
    }
    return render();
}