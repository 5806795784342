import React, { useEffect, useState } from 'react';
import { AIDnDPlatform } from './AIDnDPlatform';
import { useAIModelContext } from '../../../context/ai-model.context';
import { useAppContext } from '../../../context/app.context';
import RightSidemenu from '../../common/RightSidemenu';
import NodeConfig from './components/NodeConfig';
import { useParams } from 'react-router-dom';
import { getWorkflowAPI } from '../../../service/ai-model.service';
import { ToastType } from '../../../helper/constant';
import CustomLoader from '../../common/CustomLoader';
import { getUUID } from '../../../helper/helper';
import { useAIWorkflowContext } from '../../../context/ai-workflow.context';

const AIWorkflow = () => {
    const { updateLoader, showAlert, isLoading } = useAppContext();
    const params = useParams();
    const { updateWorkflowEdit } = useAIWorkflowContext();
    const [workflow, setWorkflow] = useState({ name: '', initialNodes: [], initialEdges: [], status: '' })

    useEffect(() => {
        if (!params || !params.id) {
            updateWorkflowEdit(true);
        }
    }, [])

    useEffect(() => {
        updateLoader(true);
        if (params.id) {
            getWorkflowAPI(params.id).then(r => {
                if (r && r.length) {
                    const { name, template: { nodes = [], edges = [] }, status } = r[0];
                    setWorkflow({ name, initialNodes: nodes, initialEdges: edges, status });
                }
            }).catch((e) => {
                showAlert(JSON.stringify(e), ToastType.ERROR);
            }).finally(() => updateLoader(false))
        }
        else {
            setWorkflow({ name: getUUID(), initialEdges: [], initialNodes: [] });
            updateLoader(false);
        }
    }, [params.id])

    const {
        showRightMenu,
    } = useAIModelContext();

    const render = () => {

        return (
            <> {isLoading && <CustomLoader full />}
                <div className="row mx-0 height-inherit">
                    <div
                        className={`px-md-0 ${showRightMenu ? "col-md-10" : "col-md-12"}`}
                    >
                        {workflow.name && <AIDnDPlatform {...{ workflow }} />}
                    </div>
                    {showRightMenu && (
                        <RightSidemenu>
                            <NodeConfig />
                        </RightSidemenu>
                    )}
                </div>
            </>
        )
    }
    return render();
}

export default AIWorkflow