import React from "react";
import AIPredictionResult from './../../ai-prediction/PredictionResults/AIPredictionResult'

const NodePrediction = ({ id, templateId, workflowId }) => {

    const render = () => {
        if (workflowId && templateId)
            return <AIPredictionResult workflowId={workflowId} templateId={templateId} isWorkflow />
    }
    return render();
}

export default NodePrediction