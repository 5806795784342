import React, { useState } from "react";

const CustomDropdown = (props) => {
  const { options, field, name, value, onChange, hasError } = props;
  const [toggle, setToggle] = useState(false);
  const render = () => {
    const selectedOptionLabel = field
      ? options.filter((opt) => opt.id == value)[0]?.[field] || `Select ${name}`
      : options.filter((opt) => opt == value)[0] || `Select ${name}`;
    return (
      <>
        {name && (
          <div className="custom-dropdown-label text-blue mb-1">
            <b>{name}</b>
          </div>
        )}
        <div className={`select ${hasError ? "border-red" : ""}`}>
          <div
            onClick={() => setToggle(!toggle)}
            className={`select-styled ${toggle ? "active" : ""} capitalize`}
          >
            {selectedOptionLabel}{" "}
          </div>
          <ul
            className={`select-options`}
            style={{ display: toggle ? "block" : "none" }}
          >
            {options.map((opt, index) => (
              <li
                onClick={() => {
                  setToggle(!toggle);
                  onChange(field ? opt.id : opt);
                }}
                key={`dropdown-${index}`}
                rel={opt[field]}
                className={`capitalize ${value === (field ? opt.id : opt) ? "is-selected" : ""}`}
              >
                {field ? opt[field] : opt}{" "}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };
  return render();
};

export default CustomDropdown;
