// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AppTitle: "Avtar AI",
  maxModelLimit: 100,
  OneSecond: 1000,
  Million: 1000000,
  minCylinderWidth: 100,
  modelScaleFactor: 20,
  modelDivisionFactor: 10000,
  modelOffset: 10,
  taskNameLimit: 100,
  workFlowId: 'avatarWorkflow',
  maxTaskLimit: 20,
  maxMultipleFileCount: 10,
  defaultAgent: 99,
  loggerTestDeviceId: "257cc63d-e47a-4677-8354-4b1ab8c1bbe7",
  churnList: [
    { id: "10", value: "10" },
    { id: "20", value: "20" },
    { id: "30", value: "30" },
    { id: "40", value: "40" },
  ],
  machineDowntime: [
    { id: "10", value: "10" },
    { id: "20", value: "20" },
    { id: "30", value: "30" },
    { id: "40", value: "40" },
  ],
  dateFormat: "DD/MMM/YYYY",
  dateTimeFormat: "DD/MMM/YYYY hh:mm A",
  fulldateTimeFormat: "DD/MMM/YYYY hh:mm:ss A",
  csvDateFormat: "YYYY_MM_DD_hh_mm_ss",
  apiTimeout: 30 * 1000,
  minCsvLines: 1000,
  maxImageSelector: 6,
  footerConfig: {
    contactUs: "+1(416) 574-3800",
    contactEmail: "sales@incorporation.ai",
    incorpAi: "https://www.incorp.ai/",
    termsAndConditions: "https://www.incorp.ai/",
    privacyPolicy: "https://www.incorp.ai/",
    copyWrite: "https://www.incorp.ai/",
  },
  imageNormalization: {
    width: 400,
    height: 400,
  },
  defLocale: {
    locale: "en-US",
    currency: "USD",
  },
  donutDimension: {
    mobile: {
      width: 400,
      height: 400,
      innerRadius: 100,
      outerRadius: 170,
      centerXOffset: 0,
      centerYOffset: 25,
    },
    desktop: {
      width: 500,
      height: 500,
      innerRadius: 130,
      outerRadius: 220,
      centerXOffset: 0,
      centerYOffset: 25,
    },
  },
  allowedFileExtensions: ["text/csv"],
  LLMModels: [19],
  countryRegion: ["us", "ca"],
  processorName:
    "projects/311033712537/locations/us/processors/d42e62072fb0eb0d",
  zohoRedirect: "zoroRedirect",
  SHARED: {
    BEARER_TOKEN: "BEARER_TOKEN",
    ZOHO_AUTH_TOKEN: "ZOHO_AUTH_TOKEN",
    ZOHO_REFRESH_TOKEN: "ZOHO_REFRESH_TOKEN",
    WORKSPACE_URL: "WORKSPACE_URL",
  },
  UNSIGNED: {
    BOOK_DEMO: "https://calendly.com/incorporation-ai/meeting",
    REVENUE_RAGE: {
      MIN: 1,
      MAX: 500,
      STEP: 1,
    },
    EMPLOYEE_RAGE: {
      MIN: 50,
      MAX: 10000,
    },
    INVENTORY_RAGE: {
      MIN: 0,
      MAX: 200,
      STEP: 1,
    },
  },
  PAGES: {
    AUTH: {
      VALUE_CALCULATOR_REPORT: "value-calculator-report",
      LOGIN: "login",
    },
    CREATE_ORG: "create-org",
    INFERENCE_DASHBOARD: "ai-inference",
    HOME: {
      HOME: "home",
      BUSINESS_OVERVIEW: "business-overview",
      PROFILE: "profile",
      USER_MANAGEMENT: "user-management",
      DASHBOARD: "dashboard",
      AI_TEMPLATE: "ai-template",
      AI_PIPELINE: "ai-pipeline",
      AI_VALUE: "ai-value",
      AI_ROADMAP: "ai-roadmap",
      AI_DATA: "ai-data",
      AI_MODEL: "ai-model",
      AI_TRAINING: "ai-training",
      AI_CREATE_AGENT: "ai-create-agent",
      AI_WORKFLOW: "ai-workflow",
    },
    AI_PREDICTION: "ai-prediction",
    ZOHO_CALLBACK: "callback",
    SOCIAL_MEDIA: {
      SOCIAL_HOME: "social-home",
      NEGATIVE_REVIEW: "social-neg-review",
      POSITIVE_REVIEW_1: "social-pos-review-1",
      POSITIVE_REVIEW_2: "social-pos-review-2",
      POSITIVE_REVIEW_3: "social-pos-review-3",
    },
  },
  EVENTS: {},
  TOAST_CONFIG: {
    placement: "top-center",
    autoDismissTimeout: 5000,
    autoDismiss: true,
  },
  MESSAGES: {
    COMMON_ERROR_MESSAGE: "Something went wrong. Please try later.",
    DELETED_MESSAGE: "deleted.",
    ADDED_MESSAGE: "added.",
    UPDATED_MESSAGE: "updated.",
    GENERAL_SUCCESS: "Activity successful.",
  },
  ALERT: {
    INFO: "info",
    WARNING: "warning",
    SUCCESS: "success",
    ERROR: "error",
  },
  API: {
    AUTH: {
      SIGN_UP: "signup",
      GET_INDUSTRIES: "industry",
      GET_TENANT_LIST: "org/gettenant",
      GET_SOLUTION_LIST: "org/features",
      CAPCHA: "validate-capcha",
      ORG: "org",
      CREATE_NEW_DB: "org/createdb",
      CREATE_CLOUD_STORAGE: "org/cloudStorage",
      CALCULATE_UNSIGNED_REVENUE: "org/savings",
      GET_UNSIGNED_REPORT_BY_MAIL: "org/create_org",
    },
    AI_MODEL: {
      GET_AI_TEMPLATES: "usertemplate",
      GET_AI_VALUE_PROFIT: "usertemplate/profits/{id}",
      GET_MODELS: "ai-models",
      GET_DEPARTMENTS: "department/unique",
      GET_TASKS: "task",
      GET_AI_VALUE: "org/savings",
      GET_ROAD_MAP: "org/roadmap/{id}",
      GET_MODEL_DETAILS: "model/{id}",
      TRANING_MODEL: "usercustommodel",
      SOCIAL_MEDIA_MODEL: "userllmmodel",
      GET_AI_DATA_WORKFLOW: "lookup/workflows",
      GET_AI_DATA_UPLOAD_STATUS: "org/ai-datastatus/{id}",
      GET_AI_PREDICT_LOOKUP: "lookup/predictLookup/{id}",
      TRIGGER_MODEL_PROCESS: "usertemplate/run",
      SOCIAL_MEDIA_PREDICTION_SENTIMENT: "LLMprediction/llmreview",
      SOCIAL_MEDIA_PREDICTION_REVIEW: "LLMprediction/review",
      SOCIAL_MEDIA_PREDICTION_QUERY: "LLMprediction/qa",
      SOCIAL_MEDIA_CREATE_AD: "LLMprediction/createads",
      SOCIAL_MEDIA_CREATE_IMAGES: "LLMprediction/createimage",
      REGRESSION_GET_DATA: "userprediction",
      REGRESSION_UPLOAD_DATA: "userprediction/upload",
      SEND_EMAIL: "userllmmodel/sendmail",
      EXTRACT_TEXT_FROM_IMAGE: "DOCAIprediction/extractTextFromImage",
      MULTI_UPLOAD_REQUEST: "usercustommodel/multiUpload",
      DOCUMENT_AI_PREDICTION: "DOCAIprediction",
      KNOWLEDGE_AI_PREDICTION: "knowledgeai",
      ASK_ME: "askme",
      AGENT: "agent",
      AGENT_TYPE: "agenttype",
      CHATBOT: "predict",
      GENERATE_BO_REPORT: "generate-report",
      GET_BO: "automate-ai",
      INFERENCE_DASHBOARD: "userprediction/{templateId}/{taskId}/getdashboard",
      INFERENCE_DASHBOARD_URL:
        "userprediction/{templateId}/{taskId}/getdashboardurl/{viewId}",
      CREATE_INFERENCE_DASHBOARD:
        "userprediction/{templateId}/{taskId}/createUpdateZohoTable",
      WORKFLOW: 'workflow',
      WORKFLOW_MODEL_LIST: 'workflow/getmodels',
      WORKFLOW_LLM_LIST: 'workflow/getllm',
      WORKFLOW_EXECUTION: 'workflow/executeworkflow',
      WORKFLOW_SCHEDULE: 'workflow/scheduleworkflow',
      WORKFLOW_DELETE_SCHEDULE: 'workflow/deletesheduleworkflow',
      WORKFLOW_LOGS: 'workflow/listworkflow'
    },
  },
  EMAIL: {
    SUBJECT: "Negative Review summary",
    EMAIL_BODY_TEMPLATE: `Hi,\n\nThese below are consolidated negative review keyword for the establishment, please analyse and  improve your service to better serve your customers.\n\nReview keywords: {keywords}\n\nRegards,\nAvtar AI`,
  },
};

export const TaskType = {
  EMPLOYEE: "EMPL",
  INVENTORY: "INVEN",
  DOWNTIME: "DOWN",
  CHURNRATE: "CHURN",
};

export const ProviderList = [
  {
    id: "OPENAI",
    value: "OPENAI",
  },
  {
    id: "BART",
    value: "BART",
  },
];

export const CurrencyType = {
  dollar: "dollar",
  pound: "pound",
  euro: "euro",
};

export const OutletList = [
  {
    id: "GOOGLE",
    value: "GOOGLE",
  },
  {
    id: "FACEBOOK",
    value: "FACEBOOK",
  },
  {
    id: "TWITTER",
    value: "TWITTER",
  },
];

export const TemplateStatus = {
  SCHEDULED: "SCHEDULED",
  NEW: "NEW",
  DRAFT: "DRAFT",
  COMPLETED: "DONE"
};

export const TaskStatus = {
  NEW: "NEW",
  RUNNING: "RUNNING",
  DONE: "DONE",
};

export const ToastType = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
};

export const ModelType = {
  TabularClassification: 1,
  TabularRegression: 2,
  ImageClassification: 3,
  MultiLabelImageClassification: 11,
  DocumentAI: 23,
  SocialMedia: 19,
  ObjectDetection: 4,
  KnowledgeAI: 18,
  TimeSerialTabular: 14,
  SensorLogger: 34,
};

export const TimeList = [
  {
    id: 1,
    value: "1 min",
  },
  {
    id: 2,
    value: "10 min",
  },
  {
    id: 3,
    value: "15 min",
  },
  {
    id: 4,
    value: "30 min",
  },
  {
    id: 5,
    value: "Hourly",
  },
  {
    id: 6,
    value: "Daily",
  },
  {
    id: 7,
    value: "Weekly",
  },
  {
    id: 8,
    value: "Monthly",
  },
  {
    id: 9,
    value: "Yearly",
  },
];

export const FILE_TYPE = {
  PDF: ["pdf"],
  TXT: ["txt"],
  EXCEL: ["xls", "xlsx"],
  CSV: ["csv"],
  WORD: ["doc", "docx"],
  WEB: "web",
};

export const UserRoles = [
  {
    id: "admin",
    value: "Admin",
  },
  {
    id: "viewer",
    value: "Viewer",
  },
  {
    id: "editor",
    value: "Editor",
  },
];

export const LanguageList = [
  {
    id: "en",
    value: "English",
  },
  {
    id: "fr",
    value: "French",
  },
];

export const DepartmentList = [
  {
    id: "marketing",
    value: "Marketing",
  },
  {
    id: "customer-service",
    value: "Customer Service",
  },
  {
    id: "it-department",
    value: "IT Department",
  },
];

export const FileType = {
  PDF: ".pdf",
  IMAGES: "image/*",
};

export const TimeSeriesTransformList = [
  "Automatic",
  "Categorical",
  "Text",
  "Timestamp",
  "Numeric",
];

export const CHART_TYPE = {
  SCATTER: "scatter",
  BAR: "bar",
  PIE: "pie",
  LINE: "line",
};

export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#83a6ed",
  "#8dd1e1",
  "#82ca9d",
  "#a4de6c",
  "#d0ed57",
  "#ffc658",
];

export const EvaluateType = {
  MAE: "MAE",
  MAPE: "MAPE",
  RMSE: "RMSE",
  RMSLE: "RMSLE",
  RS2: "RS2",
};

export const SOCKET = {
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
};

export const SensorType = {
  Orientation: "orientation",
  Microphone: "microphone",
  LightSensor: "light",
  Gyroscope: "gyroscope",
  Gravity: "gravity",
  Accelarometer: "accelerometer",
  Magnatometer: "magnetometer",
};

export const ZohoAuthConfig = {
  clientId: process.env.REACT_APP_ZOHO_CLIENT_ID,
  redirectUri: process.env.REACT_APP_ZOHO_REDIRECT_URI,
  authUrl: process.env.REACT_APP_ZOHO_AUTH_URL,
  responseType: "token",
  scope: "ZohoCRM.modules.ALL",
};

export const CRM_LIST = {
  ZOHO: "zoho",
};

export const GroupByType = {
  agent: "agent_name",
  date: "timestamp",
};

export const NodeType = {
  InputNode: 'inputNode',
  AgentNode: 'agentNode',
  OutputNode: 'outputNode'
}

export const AvailableNodes = [
  {
    type: NodeType.InputNode,
    name: 'Input',
    color: '#DAEAFD',
  },
  {
    type: NodeType.AgentNode,
    name: 'Agent',
    color: '#FFF0C8',
  }, {
    type: NodeType.OutputNode,
    name: 'Output',
    color: '#DEF4D2',
  }
];

export const InputTypes = {
  ManualInput: 1
};

export const LLMTypes = [
];