import React, { useEffect, useState } from "react";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";
import { useAIModelContext } from "../../../../context/ai-model.context";
import { useReactFlow } from "@xyflow/react";
import { LLMTypes } from "../../../../helper/constant";
import CustomDropdown from "../../../common/CustomDropdown";

const AgentNodeConfig = (props) => {
    const [forms, setForms] = useState({ model: null, llm: '', prompt: '', agentName: '' });
    const { setNodes } = useReactFlow();
    const { updateRightMenu } = useAIModelContext();
    const { selectedNode, workflowModels, getWorkflowModelList, llmList, getWorkflowLLMList, workflowEdit } = useAIWorkflowContext();

    useEffect(() => {
        getWorkflowModelList();
        getWorkflowLLMList();
    }, [])

    useEffect(() => {
        if (selectedNode && selectedNode.data) {
            setForms({
                model: selectedNode.data.model,
                llm: selectedNode.data.llm,
                prompt: selectedNode.data.prompt,
                agentName: selectedNode.data.agentName
            });
        }

    }, [selectedNode]);

    const renderModel = () => {
        return (
            <div>
                <CustomDropdown
                    name="Choose Model"
                    options={workflowModels}
                    field="name"
                    value={forms.model}
                    onChange={model => setForms({ ...forms, model })}
                />
            </div>
        )
    }

    const renderLLMType = () => {
        return (
            <div>
                <CustomDropdown
                    name="Choose LLM"
                    options={llmList}
                    field="value"
                    value={forms.llm}
                    onChange={llm => setForms({ ...forms, llm })}
                />
            </div>
        )
    }

    const renderAgentName = () => {
        return (
            <div className="d-flex justify-content-between align-items-center"
            >
                <input
                    className={`form-control`}
                    placeholder="Agent Name"
                    value={forms.agentName}
                    onChange={(e) => setForms({ ...forms, agentName: e.currentTarget.value })}
                />
            </div>
        )
    }

    const renderPrompt = () => {
        return (
            <div className="d-flex justify-content-between align-items-center"
            >
                <textarea
                    rows={2}
                    style={{ minHeight: 80 }}
                    className={`form-control`}
                    placeholder="Prompt"
                    value={forms.prompt}
                    onChange={(e) => setForms({ ...forms, prompt: e.currentTarget.value })}
                />
            </div>
        )
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const selectedModel = workflowModels.filter(i => i.id === forms.model)[0];
        const llmItem = llmList.filter(i => i.id === forms.llm)[0];
        const { id, ...modelData } = selectedModel;
        setNodes((nodes) => nodes.map((node) =>
            node.id === selectedNode.id
                ? { ...node, data: { ...node.data, ...forms, ...modelData, llm_type: llmItem?.model_type } }
                : node
        ));
        updateRightMenu(false);
    }

    const renderSaveButton = () => {
        return (
            <div className="form-group">
                <button
                    type="submit"
                    className="btn2 btn radious-0 w-100 f-13 text-uppercase py-2 font15 fontweight600">
                    save
                </button>
            </div>
        )
    }
    const renderConfigForm = () => {
        return (
            <form className="d-flex flex-column gap-2" onSubmit={onFormSubmit} noValidate>
                {renderModel()}
                {renderAgentName()}
                {renderLLMType()}
                {renderPrompt()}
                {renderSaveButton()}
            </form>
        )
    }

    const renderConfigrationStatus = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-1">
                <p className="p-0 m-0">
                    <span className="text-black">Agent Name :</span>
                    <span className="text-secondary"> {selectedNode.data.agentName}</span>
                </p>
                <p className="p-0 m-0">
                    <span className="text-black">Model Name :</span>
                    <span className="text-secondary"> {selectedNode.data.name}</span>
                </p>
                <p className="p-0 m-0">
                    <span className="text-black">LLM Type :</span>
                    <span className="text-secondary"> {selectedNode.data.llm_type}</span>
                </p>
                <p className="p-0 m-0">
                    <span className="text-black">Prompt :</span>
                    <span className="text-secondary"> {selectedNode.data.prompt || '--'}</span>
                </p>
            </div>
        )
    }

    const render = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-3 pt-3">
                <h5>Agent Node Configuration</h5>
                {workflowEdit ? renderConfigForm() : renderConfigrationStatus()}
            </div>
        )
    }
    return render();
}

export default AgentNodeConfig