import React, { useEffect, useState } from "react";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";
import { NodeType } from "../../../../helper/constant";
import { useReactFlow } from "@xyflow/react";
import { useAIModelContext } from "../../../../context/ai-model.context";

const OutputNodeConfig = (props) => {
    const [forms, setForms] = useState({ target: '' });
    const { setNodes } = useReactFlow();
    const { updateRightMenu } = useAIModelContext();
    const { selectedNode } = useAIWorkflowContext();

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (selectedNode && selectedNode.data) {
            setForms({
                target: selectedNode.data.target
            });
        }

    }, [selectedNode]);

    const renderTarget = () => {
        return (
            <div className="d-flex justify-content-between align-items-center"
            >
                <input
                    className={`form-control`}
                    placeholder="Output target"
                    value={forms.target}
                    onChange={(e) => setForms({ ...forms, target: e.currentTarget.value })}
                />
            </div>
        )
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const { id } = selectedNode;
        setNodes((nodes) => nodes.map((node) =>
            node.id === id
                ? { ...node, data: { ...node.data, ...forms } }
                : node
        ));
        updateRightMenu(false);
    }

    const renderSaveButton = () => {
        return (
            <div className="form-group">
                <button
                    type="submit"
                    className="btn2 btn radious-0 w-100 f-13 text-uppercase py-2 font15 fontweight600">
                    save
                </button>
            </div>
        )
    }
    const renderConfigForm = () => {
        return (
            <form className="d-flex flex-column gap-2" onSubmit={onFormSubmit} noValidate>
                {renderTarget()}
                {renderSaveButton()}
            </form>
        )
    }


    const render = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-3 pt-3">
                <h5>Output Node Configuration</h5>
                {renderConfigForm()}
            </div>
        )
    }
    return render();

}

export default OutputNodeConfig