import React, { useEffect, useState } from 'react';
import { AvailableNodes, NodeType, TemplateStatus } from '../../../../helper/constant';
import { useAIModelContext } from '../../../../context/ai-model.context';
import { useAIWorkflowContext } from '../../../../context/ai-workflow.context';
import { Handle } from '@xyflow/react';
import { renderNodeIcon } from '../../../../helper/helper';
import { Link, useParams } from 'react-router-dom';
import NodePrediction from '../components/NodePrediction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCheckCircle, faCirclePlay, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

const AgentNode = ({ data, id, workflow }) => {
    const { updateRightMenu, updatePredictionModel } = useAIModelContext();
    const { updateSelecteNode } = useAIWorkflowContext();
    const params = useParams();


    const handleNodeClick = () => {
        updateRightMenu(true);
        updateSelecteNode({ id, data })
    };

    const onShowPrediction = (e) => {
        e.stopPropagation();
        const model = {
            model_name: data.label,
            taskId: data.taskId,
            modelId: data.modelType,
            templateId: data.templateId
        };
        updatePredictionModel({ ...model });
    }

    const renderViewResults = () => {
        if (workflow && workflow.status === TemplateStatus.COMPLETED)
            return (
                <Link onClick={onShowPrediction} className='pb-2 align-self-center fs-10'>{<FontAwesomeIcon icon={faChartLine} />}</Link>
            )
    }

    const renderNodeDetails = () => {
        if (data && data.name) {
            return (
                <div className='d-flex flex-column'>
                    <div className='p-2 node-details'>
                        <p className='p-0 m-0 text-black'>Name: <span className='text-secondary'>{data.agentName}</span></p>
                        <p className='p-0 m-0 text-black'>Model: <span className='text-secondary'>{data.model}</span></p>
                    </div>
                    {renderViewResults()}
                </div>
            )
        }
    }

    const renderNodePrediction = () => {
        return <NodePrediction id={id} templateId={data.templateId} workflowId={params.id} />
    }

    const render = () => {
        if (data) {
            return (
                <>
                    <div className={`dndnode d-flex flex-column gap-1 input ${NodeType.AgentNode} `} onClick={handleNodeClick}>
                        {data &&
                            <div className='p-2 m-0 node-title d-flex justify-content-between align-items-center' style={{ backgroundColor: AvailableNodes[1].color }}>
                                <div className='d-flex align-items-center'>
                                    {renderNodeIcon(data.type, 25)}
                                    <p className='p-0 m-0'>{data.label}</p>
                                </div>
                                {/* {workflow.status === TemplateStatus.COMPLETED && <FontAwesomeIcon icon={faCheckCircle} size='1x' color='green' />} */}
                            </div>
                        }
                        {renderNodeDetails()}
                        <Handle
                            type="target"
                            position="left"
                            style={{ background: '#555' }}
                            id={`${id}-target`}
                            isConnectable={true}
                        />
                        <Handle
                            type="source"
                            position="right"
                            style={{ background: '#555' }}
                            id={`${id}-source`}
                            isConnectable={true}
                        />
                    </div>
                    {renderNodePrediction()}
                </>
            )
        }
    }
    return render();
}

export default AgentNode