import React from "react";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";
import { NodeType } from "../../../../helper/constant";
import InputNodeConfig from "./InputNodeConfig";
import AgentNodeConfig from "./AgentNodeConfig";
import { useAIModelContext } from "../../../../context/ai-model.context";
import OutputNodeConfig from "./OutputNodeConfig";

const NodeConfig = (props) => {
    const { hideRightMenu } = useAIModelContext();
    const { selectedNode } = useAIWorkflowContext();

    const renderConditionalConfig = () => {
        const { data } = selectedNode;
        switch (data.type) {
            case NodeType.AgentNode:
                return <AgentNodeConfig {...props} />;
            case NodeType.OutputNode:
                return <OutputNodeConfig {...props} />;
            default:
                return <InputNodeConfig {...props} />;
        }
    }

    const renderCloseRightMenu = () => {
        return (
            <button
                type="button"
                className="btn2 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
                onClick={() => hideRightMenu()}
            >
                Close
            </button>
        )
    }

    const render = () => {
        return (
            <div className="d-flex flex-column">
                {renderConditionalConfig()}
                {renderCloseRightMenu()}
            </div>
        )
    }
    return render();
}

export default NodeConfig