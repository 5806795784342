import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import constant from "./../../../helper/constant";
import { getAgentsAPI } from "../../../service/ai-model.service";
import AgentListItem from "./AgentListItem";

const AgentList = () => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getAgentsAPI().then((result) => setAgents(result));
  }, []);

  const renderAgents = () => {
    if (agents.length)
      return agents.map((item, index) => (
        <AgentListItem item={item} key={index} />
      ));
  };

  const renderAgentList = () => {
    return (
      <div className="mb-4">
        <div className=" h6 position-relative font-weight-bold">Agents</div>
        <div className=" d-flex align-items-start gap-2">
          <Link className="mt-2" to={`/${constant.PAGES.HOME.AI_CREATE_AGENT}`}>
            <svg
              width="40"
              height="40"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27" cy="27" r="27" fill="#007BFF" />
              <path
                d="M28.7292 16V24.8125H37.5417V28.7292H28.7292V37.5417H24.8125V28.7292H16V24.8125H24.8125V16H28.7292Z"
                fill="#FDFBFB"
              />
            </svg>
          </Link>
          <div className="agentWrapbox d-flex gap-3 align-items-center">
            {renderAgents()}
          </div>
        </div>
      </div>
    );
  };
  const render = () => {
    return renderAgentList();
  };
  return render();
};

export default AgentList;
