import React, { useEffect, useState } from "react";
import {
  getDocumentAIPrediction,
  postDocumentPrediction,
} from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useParams } from "react-router-dom";
import { ToastType } from "../../../../../helper/constant";
import ImageUploadForm from "../../../../common/ImageUploadForm";
import CustomLoader from "../../../../common/CustomLoader";
import DocumentAITable from "./DocumentAITable";

const DocumentAI = () => {
  const [documentList, setDocumentList] = useState({
    list: [],
    columns: [],
    original: [],
  });
  const { updateLoader, isLoading, showAlert } = useAppContext();
  const params = useParams();
  const { selectedPredictionModel } = useAIModelContext();

  useEffect(() => {
    getDocumentPredictionList();
  }, []);

  const getDocumentPredictionList = () => {
    const { taskId, templateId = params.id } = selectedPredictionModel;
    if (templateId) {
      updateLoader(true);
      getDocumentAIPrediction(templateId, taskId)
        .then((result) => {
          if (result && result.length) {
            const documents = extractDocumentList(result);
            setDocumentList(documents);
          } else showAlert("No results found", ToastType.INFO);
        })
        .catch(console.log)
        .finally(() => updateLoader(false));
    }
  };

  const extractDocumentList = (result) => {
    const list = result.map((item) => {
      const {
        id,
        data: { entity, image },
      } = item;
      let rowObject = { image, id };
      entity.forEach((item) => {
        const { type, mentionText } = item;
        rowObject[type] = mentionText;
      });
      return rowObject;
    });
    return {
      columns: Object.keys(list[0]),
      list,
      original: result.slice(),
    };
  };

  const onSubmit = (image) => {
    if (image) {
      const { taskId ,templateId = params.id} = selectedPredictionModel;
      updateLoader(true);
      const formData = new FormData();
      formData.append("csv", image);
      postDocumentPrediction(templateId, taskId, formData)
        .then(() => getDocumentPredictionList())
        .catch((ex) => {
          updateLoader(false);
          showAlert(JSON.stringify(ex), ToastType.ERROR);
        });
    }
  };

  const renderImagePredictionGrid = () => {
    if (documentList.list.length)
      return (
        <DocumentAITable
          onSave={() => getDocumentPredictionList()}
          columns={documentList.columns}
          list={documentList.list}
          original={documentList.original}
        />
      );
  };

  const render = () => {
    return (
      <div className="d-flex flex-column gap-4">
        {isLoading && <CustomLoader full />}
        <ImageUploadForm onSubmit={onSubmit} fileType={"image/*,.pdf"} />
        {renderImagePredictionGrid()}
      </div>
    );
  };
  return render();
};

export default DocumentAI;
