// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  selectedNode: null,
  selectedWorkflow: null,
  workflows: [],
  workflowModels: [],
  inputTypes: [],
  llmList: [],
  workflowEdit: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.payload,
      };
    case ACTION.UPDATE_SELECTED_WORKFLOW:
      return {
        ...state,
        selectedWorkflow: action.payload,
      };
    case ACTION.UPDATE_WORKFLOW_LIST:
      return {
        ...state,
        workflows: action.payload,
      };
    case ACTION.UPDATE_WORKFLOW_MODLE_LIST:
      return {
        ...state,
        workflowModels: action.payload,
      };
    case ACTION.UPDATE_WORKFLOW_INPUT_LIST:
      return {
        ...state,
        inputTypes: action.payload,
      };
    case ACTION.UPDATE_WORKFLOW_LLM_LIST:
      return {
        ...state,
        llmList: action.payload,
      };
    case ACTION.UPDATE_WORKFLOW_EDIT:
      return {
        ...state,
        workflowEdit: action.payload,
      };
    case ACTION.CLEAR_WORKFLOW_INFO:
      return {
        ...state,
        selectedWorkflow: null,
      };
    default:
      return state;
  }
};

const useAIWorkflowReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
};

export default useAIWorkflowReducer;
