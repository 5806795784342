import React from 'react';
import { useDnD } from '../../../context/ai-dnd.contetxt';
import { AvailableNodes, NodeType } from '../../../helper/constant';
import images from '../../../helper/images';
import { renderNodeIcon } from '../../../helper/helper';

export default () => {
    const [_, setType] = useDnD();

    const onDragStart = (event, nodeType) => {
        setType(nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    const getBg = (type) => {
        switch (type) {
            case NodeType.AgentNode:
                return AvailableNodes[1].color
            case NodeType.OutputNode:
                return AvailableNodes[2].color
            default:
                return AvailableNodes[0].color
        }
    }
    const renderAvaialbeNodes = () => {
        return (
            AvailableNodes.map((node, key) => {
                const { type, color, name } = node;
                return (
                    <div
                        key={`sidebar-item-${key}`}
                        onDragStart={(event) => onDragStart(event, type)}
                        draggable
                        className={`btn-dragable ${type}`}
                    >
                        <button className='btn btn-clear btn-dnd'>
                            {renderNodeIcon(type)}
                        </button>
                    </div>
                )
            })
        )
    }

    return (
        <aside className='sidebar'>
            <div className="description p-0 m-0 text-center fs-12">You can drag these nodes to the pane on the above.</div>
            <div className='dnd-buttons'>
                {renderAvaialbeNodes()}
            </div>
        </aside>
    );
};
