import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseEdge, getBezierPath, useReactFlow } from '@xyflow/react';
import React, { useCallback } from 'react';
import { useAIWorkflowContext } from '../../../../context/ai-workflow.context';

const ButtonEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    style
}) => {
    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const { workflowEdit } = useAIWorkflowContext();

    const onEdgeRemove = useCallback(
        (id) => setEdges((eds) => {

            return eds.filter((edge) => edge.id !== id)
        }),
        [setEdges]
    );

    return (
        <g>
            {/* Edge Path */}
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />

            {/* Delete Button */}
            <foreignObject x={midX - 15} y={midY - 10} width={30} height={20}>
                <button
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 18,
                        height: 18,
                        borderRadius: '50%',
                        border: 0,
                        cursor: 'pointer',
                        backgroundColor: 'white'
                    }}
                    className='error-msg'
                    onClick={() => workflowEdit ? onEdgeRemove(id) : null}
                >
                    <FontAwesomeIcon icon={faTrashCan} />
                </button>
            </foreignObject>
        </g>
    );
};

export default ButtonEdge;
