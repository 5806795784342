import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { addEdge, Controls, MarkerType, ReactFlow, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';
import { DnDProvider, useDnD } from '../../../context/ai-dnd.contetxt';
import Sidebar from './Sidebar';
import { dataURLToBlob, getHtmlToImgData, getUUID } from '../../../helper/helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InputNode from './node-types/InputNode';
import AgentNode from './node-types/AgentNode';
import ButtonEdge from './components/ButtonEdge';
import OutputNode from './node-types/OutputNode';
import { createWorkflowAPI, executeWorkflowAPI, saveWorkflowImageAPI, scheduleWorkflowAPI } from '../../../service/ai-model.service';
import { useAppContext } from '../../../context/app.context';
import constant, { InputTypes, NodeType, TemplateStatus, ToastType } from '../../../helper/constant';
import { useAIWorkflowContext } from '../../../context/ai-workflow.context';
import WorkflowManualInput from './components/WorkflowInput';
import { useAuthContext } from '../../../context/auth.context';
import WorkflowLogs from './components/WorkflowLogs';


const DnDFlow = (props) => {
    const { name, initialEdges = [], initialNodes = [] } = props;
    const { showAlert, updateLoader, isLoading } = useAppContext();
    const { userInfo } = useAuthContext();
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [workflow, setWorkflow] = useState(name || getUUID());
    const [prompt, setPrompt] = useState('');
    const { screenToFlowPosition } = useReactFlow();
    const [showModal, setShowModal] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [type] = useDnD();
    const params = useParams();
    const { workflowEdit, updateWorkflowEdit } = useAIWorkflowContext();
    const navigate = useNavigate();

    const nodeTypes = useMemo(
        () => ({
            inputNode: (nodeProps) => <InputNode {...nodeProps} setNodes={setNodes} workflow={{ ...props }} />,
            agentNode: (nodeProps) => <AgentNode {...nodeProps} setNodes={setNodes} workflow={{ ...props }} />,
            outputNode: (nodeProps) => <OutputNode {...nodeProps} setNodes={setNodes} workflow={{ ...props }} />,
        }),
        [setNodes]
    );

    useEffect(() => {
        setEdges((eds) => [...eds]);
    }, []);

    const edgeTypes = useMemo(() => ({
        buttonEdge: (edgeProsp) => <ButtonEdge {...edgeProsp} />,
    }), []);

    const onConnect = useCallback(
        (connection) =>
            setEdges((eds) =>
                addEdge({
                    ...connection, type: 'buttonEdge', data: { label: 'Custom Edge' }, markerEnd: {
                        type: MarkerType.ArrowClosed,
                    }
                }, eds)
            ),
        [setEdges]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            // check if the dropped element is valid
            if (!type) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const newNode = {
                id: getUUID(),
                type,
                position,
                data: { label: `${type.slice(0, -4)}`, type }
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [screenToFlowPosition, type],
    );

    const onPublish = () => {
        if (nodes.length < 2 || edges.length < 1) {
            showAlert('Workflow should have atleast 2 nodes and 1 connection', ToastType.WARNING);
            return false;
        }
        updateLoader(true);
        createWorkflowAPI(
            {
                name: workflow,
                prompt,
                template: { nodes, edges }
            }, params.id)
            .then(async (flow) => {
                try {
                    const imgData = await getHtmlToImgData(constant.workFlowId);
                    const filedata = new FormData();
                    const imgBlob = dataURLToBlob(imgData);
                    const imageId = getUUID();
                    filedata.append("png", imgBlob, `${imageId}.png`);
                    await saveWorkflowImageAPI(filedata, flow[0] || params.id);
                    handleCreateWorkflow(flow[0]);
                    updateLoader(false);
                } catch (e) {
                    updateLoader(false);
                    handleCreateWorkflow(flow[0]);
                }
            }).catch(e => {
                showAlert(JSON.stringify(e), ToastType.ERROR);
                updateLoader(false)
            });
    }

    const handleCreateWorkflow = (id) => {
        updateWorkflowEdit(false);
        if (id) {
            navigate(`/${constant.PAGES.HOME.AI_WORKFLOW}/${id}`);
        }
        scheduleworkflow();
        showAlert(constant.MESSAGES.GENERAL_SUCCESS, ToastType.SUCCESS);
    }

    const scheduleworkflow = () => {
        const inputNode = nodes.filter(item => item.type === NodeType.InputNode)[0];
        if (inputNode.data.schedule) {
            const payload = {
                name: getWorkflowName(),
                workflowName: getWorkflowName(),
                schedule: inputNode.data.schedule
            };
            scheduleWorkflowAPI(payload).then((r) => r).catch(e => e);
        }
    }

    const onRun = () => {
        const inputNode = nodes.filter(item => item.type === NodeType.InputNode);
        if (props.status === TemplateStatus.COMPLETED &&
            inputNode.length &&
            inputNode[0].data &&
            inputNode[0].data.inputType === InputTypes.ManualInput) {
            setShowModal(true);
        } else {
            executeWorkflow();
        }
    }

    const getWorkflowName = () => `workflow-${userInfo.org.org_name}${workflow}`;

    const executeWorkflow = (data) => {
        setShowModal(false);
        updateLoader(true)
        const payload = {
            name: getWorkflowName()
        };
        if (data) {
            payload['input'] = data;
        }
        executeWorkflowAPI(payload)
            .then((r) => {
                if (r && r.state === 'SUCCEEDED') {
                    showAlert('Workflow executed successfully', ToastType.SUCCESS);
                } else {
                    showAlert(JSON.stringify(r.error), ToastType.ERROR);
                }
            })
            .catch((e) => showAlert(JSON.stringify(e), ToastType.ERROR))
            .finally(() => updateLoader(false));
    }

    const renderTitleContainer = () => {
        return (
            <div className='d-flex align-items-start justify-content-between'>
                <div className='px-3'>
                    <div className='d-flex pt-4 pb-2 px-4 align-items-center justify-content-center py-2 gap-2 mr-2'>
                        <h6 className='py-1' style={{ minWidth: 150 }}>Workflow Title</h6>
                        <div className='ai-workflow-container justify-content-start align-items-center gap-2 flex-1'>
                            <input
                                type="text"
                                className="flex-1 form-control workflow-input"
                                placeholder="Workflow name"
                                value={workflow || ''}
                                onChange={(e) => setWorkflow(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='d-flex pt-2 pb-2 px-4 align-items-center justify-content-center py-2 gap-2 mr-2'>
                        <h6 className='py-1' style={{ minWidth: 150 }}>Prompt</h6>
                        <div className='ai-workflow-container justify-content-start align-items-center gap-2 flex-1'>
                            <input
                                type="text"
                                className="flex-1 form-control workflow-input"
                                placeholder="Prompt"
                                value={prompt || ''}
                                onChange={(e) => setPrompt(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {renderWorklogAction()}
            </div>
        )
    }

    const renderEditButton = () => {
        if (workflowEdit) {
            return (
                <div className="d-flex align-items-center justify-content-center gap-2" >
                    <Link className="btn2 btn-dragable1 py-3" onClick={() => updateWorkflowEdit(false)}>Cancel</Link>
                    <Link className="btn2 btn-dragable1 bg-dark py-3" onClick={() => onPublish()}>Publish</Link>
                </div>
            )
        }
        return (
            <div className="d-flex align-items-center justify-content-center gap-2" >
                <Link className="btn2 btn-dragable1 py-3" onClick={() => updateWorkflowEdit(true)}>Edit</Link>
                <Link className="btn2 btn-dragable1 bg-dark py-3" onClick={() => onRun()}>Run</Link>
            </div>
        )
    }

    const renderActionButtons = () => {
        if (params.id) {
            return renderEditButton();
        }

        return (
            <div className="d-flex align-items-center justify-content-center gap-2" >
                <Link className="btn2 btn-dragable1 bg-dark py-3" onClick={() => onRun()}>Run</Link>
                <Link className="btn1 arrowhover btn-dragable2 d-flex align-items-cent justify-content-between font-weight-bold" onClick={() => onPublish()}>PUBLISH<span>
                    <svg width="25" height="24" viewBox="0 0 45 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z" fill="white"></path>
                    </svg></span></Link>
            </div>
        )
    }

    const renderWorkManualInput = () => {
        const inputNode = nodes.filter(item => item.type === NodeType.InputNode);
        if (props.status === TemplateStatus.COMPLETED &&
            inputNode.length &&
            inputNode[0].data &&
            inputNode[0].data.inputType === InputTypes.ManualInput) {
            return (
                <WorkflowManualInput
                    nodes={nodes}
                    setShowModal={() => setShowModal(false)}
                    showModal={showModal}
                    onExecute={(data) => executeWorkflow(data)} />
            )
        }
    }

    const renderDNDPlatform = () => {
        return (
            <div className="dndflow ai-workflow">
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                        id={constant.workFlowId}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        fitView
                        nodesConnectable={workflowEdit || !params.id}
                        nodesFocusable={workflowEdit || !params.id}
                        edgesReconnectable={workflowEdit || !params.id}
                        edgesFocusable={workflowEdit || !params.id}
                        elevateEdgesOnSelect={workflowEdit || !params.id}
                        nodesDraggable={workflowEdit || !params.id}
                    // elementsSelectable={workflowEdit || !params.id}
                    >
                        <Controls />
                    </ReactFlow>
                </div>
                {(workflowEdit || !params.id) && <Sidebar />}
            </div>
        )
    }

    const renderWorklogAction = () => {
        if (props.status === TemplateStatus.COMPLETED)
            return <Link className='pt-4 pr-5' onClick={() => setShowLogs(true)} >Show Logs</Link>
    }

    const renderWorkflowLogs = () => {
        if (showLogs)
            return <WorkflowLogs
                name={getWorkflowName()}
                setShowModal={() => setShowLogs(false)}
                showModal={showLogs} />
    }

    return (
        <>
            {renderTitleContainer()}
            {renderDNDPlatform()}
            {renderActionButtons()}
            {renderWorkManualInput()}
            {renderWorkflowLogs()}
        </>
    );
};

export const AIDnDPlatform = (props) => {
    return (
        <DnDProvider>
            <DnDFlow {...props.workflow} />
        </DnDProvider>)
};