import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { CHART_TYPE } from "../../../../../helper/constant";
import CustomLoader from "./../../../../common/CustomLoader";
import { convertToNumber } from "../../../../../helper/helper";
import {
  getDynamicChartTypes,
  getPredictionExplainData,
} from "../../../../../service/ai-model.service";
import PredChartGroup from "../widgets/PredChartGroup";
import { useScreenSizeContext } from "../../../../../context/mobile-layout.context";

const RegressionExplainable = () => {
  const { predictionData } = useAIModelContext();
  const [chartList, setChartList] = useState([]);
  const [predictionExplanation, setPredictionExplanation] = useState(null);
  const [loading, setLoading] = useState(false);
  const { selectedPredictionModel } = useAIModelContext();
  const params = useParams();
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    if (selectedPredictionModel && predictionData) {
      const { taskId, templateId = params.id } = selectedPredictionModel;
      setChartList([]);
      setPredictionExplanation(null);
      setLoading(true);
      Promise.all([
        getDynamicChartTypes(templateId, taskId),
        getPredictionExplainData(templateId, taskId),
      ])
        .then((result) => {
          if (result[0]) {
            const chartConfigs = setChartConfig(result[0]?.visualizations);
            setChartList(chartConfigs || []);
          }

          if (result[1]) {
            setPredictionExplanation(result[1]);
          }
        })
        .catch((ex) => console.log(ex))
        .finally(() => setLoading(false));
    }
  }, [predictionData]);

  const setChartConfig = (list) => {
    return list.map((chart) => {
      let config = {};
      switch (chart.type) {
        case CHART_TYPE.SCATTER:
          config = transformScatterConfig(chart);
          break;
        case CHART_TYPE.BAR:
          config = {};
          break;
        case CHART_TYPE.PIE:
          config = {};
          break;
      }
      chart.config = config;
      return chart;
    });
  };

  const transformScatterConfig = (config) => {
    if (predictionData) {
      const transformData = predictionData.map((item) => {
        let data = {};
        data[config.columns[0]] = convertToNumber(item[config.columns[0]]);
        data[config.columns[1]] = convertToNumber(item[config.columns[1]]);
        return data;
      });
      return {
        title: config.title,
        labels: [config.xAxis, config.yAxis],
        datakeys: [config.columns[0], config.columns[1]],
        data: transformData,
      };
    }
  };

  const renderCharts = () => {
    return (
      <div className="d-flex flex-column gap-2 flex-1">
        <PredChartGroup chartList={chartList} />
      </div>
    );
  };

  const renderExplain = () => {
    return (
      <div className="flex-2">
        <p
          className="m-1 preserve-line-breaks"
          dangerouslySetInnerHTML={{ __html: predictionExplanation }}
        ></p>
      </div>
    );
  };

  const render = () => {
    if (predictionData)
      return (
        <>
          {loading && <CustomLoader full />}
          <div
            className={`d-flex justify-content-start gap-2 min-vh-50 ${isMobileScreen ? "flex-column pb-5" : ""}`}
          >
            <>{renderExplain()}</>
            <>{renderCharts()}</>
          </div>
        </>
      );
  };
  return render();
};

export default RegressionExplainable;
