import React from "react";
import images from "./../../../helper/images";
import { Link, useNavigate } from "react-router-dom";
import constant, { TemplateStatus, ToastType } from "./../../../helper/constant";
import { useAIModelContext } from "../../../context/ai-model.context";
import {
  createNewTemplateData,
  formatDate,
  getTimeStamp,
} from "../../../helper/helper";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";
import { useAppContext } from "../../../context/app.context";
import { useAIWorkflowContext } from "../../../context/ai-workflow.context";

const RecentModelItem = (props) => {
  const { template, type } = props;
  const navigate = useNavigate();
  const { showAlert } = useAppContext();
  const { clearModelInfo, createTemplateWithoutData, deleteDraftTemplate } =
    useAIModelContext();
  const { createWorkflow, deleteWorkflow, clearWorkflowInfo } =
    useAIWorkflowContext();
  const { isMobileScreen } = useScreenSizeContext();

  const getNavigationLink = (template) => {
    if (type) {
      return `${constant.PAGES.HOME.AI_WORKFLOW}/${template.id}`;
    } else {
      if (template.status === TemplateStatus.COMPLETED)
        return `${constant.PAGES.AI_PREDICTION}/${template.id}`;
      if (template.status === TemplateStatus.SCHEDULED)
        return `${constant.PAGES.HOME.AI_MODEL}/${template.id}`;
      return `${constant.PAGES.HOME.AI_PIPELINE}/${template.id}`;
    }
  };

  const duplicateTemplate = () => {
    if (type) {
      const data = { ...template };
      data.name = `${data.name}-${getTimeStamp()}`;
      createWorkflow(data, (id) => {
        navigate(`/${constant.PAGES.HOME.AI_WORKFLOW}/${id}`);
      })
    } else {
      const data = { ...template };
      const newTemplate = createNewTemplateData(data);
      newTemplate.template_name = `${newTemplate.template_name}-${getTimeStamp()}`;
      createTemplateWithoutData(newTemplate, (id) => {
        navigate(`/${constant.PAGES.HOME.AI_PIPELINE}/${id}`);
      });
    }
  };

  const changePage = () => {
    clearModelInfo();
    clearWorkflowInfo();
  };

  const deleteTemplate = (temp) => {
    if (type) {
      deleteWorkflow(temp.id);
    } else
      deleteDraftTemplate(temp.id);
  };

  const render = () => {
    return renderRecentModelItem();
  };

  const renderViewDetails = (status) => {
    let dynamicClass = "btnblue";
    if (status === TemplateStatus.SCHEDULED) {
      dynamicClass = "btnorange";
    }
    if (status === TemplateStatus.COMPLETED) {
      dynamicClass = "btngreen";
    }
    return (
      <Link
        onClick={() => changePage()}
        to={getNavigationLink(template)}
        className={`border px-3 py-2 ${dynamicClass}`}
      >
        View Detail
      </Link>
    );
  };

  const renderMoreOptions = (status) => {
    return (
      <div className="p-2 dropdown">
        <div
          className="cursor-pointer"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg width="10" height="27" viewBox="0 0 15 37" fill="none">
            <g>
              <circle cx="7.5" cy="3.5" r="3.5" fill="#217CE8" />
              <circle cx="7.5" cy="14.5" r="3.5" fill="#217CE8" />
              <circle cx="7.5" cy="25.5" r="3.5" fill="#217CE8" />
            </g>
          </svg>
        </div>
        <div
          className={`dropdown-menu font-14 py-0 ${isMobileScreen ? "left-n-5" : ""}`}
          aria-labelledby="dropdownMenuButton"
          x-placement="bottom-start"
        >
          {(status === TemplateStatus.NEW ||
            status === TemplateStatus.DRAFT) && (
              <Link
                className="dropdown-item"
                onClick={() => changePage()}
                to={type ? `${constant.PAGES.HOME.AI_WORKFLOW}/${template.id}` : `${constant.PAGES.HOME.AI_PIPELINE}/${template.id}`}
              >
                <span className="mr-2">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path
                      d="M12.8857 2.19512C12.6806 1.9794 12.4343 1.80692 12.1615 1.68782C11.8887 1.56872 11.5948 1.50542 11.2971 1.50164C10.9994 1.49787 10.704 1.5537 10.4283 1.66584C10.1525 1.77798 9.902 1.94417 9.69145 2.15462L2.6602 9.18587C2.41444 9.43156 2.24377 9.74219 2.1682 10.0814L1.51045 13.0439C1.49673 13.1054 1.49875 13.1695 1.51634 13.23C1.53393 13.2906 1.56651 13.3458 1.61108 13.3904C1.65564 13.4351 1.71075 13.4677 1.77129 13.4854C1.83183 13.5031 1.89586 13.5052 1.95745 13.4916L4.9027 12.8369C5.25328 12.7588 5.57433 12.5824 5.8282 12.3284L12.8467 5.30987C13.2581 4.8983 13.4924 4.3423 13.4997 3.76043C13.507 3.17856 13.2867 2.61686 12.8857 2.19512ZM10.2217 2.68487C10.3589 2.54571 10.5223 2.43506 10.7024 2.35931C10.8825 2.28356 11.0759 2.2442 11.2713 2.2435C11.4667 2.2428 11.6603 2.28077 11.841 2.35523C12.0217 2.42969 12.1858 2.53916 12.324 2.67734C12.4622 2.81552 12.5716 2.97967 12.6461 3.16034C12.7206 3.34102 12.7585 3.53464 12.7578 3.73005C12.7571 3.92546 12.7178 4.11881 12.642 4.29894C12.5663 4.47907 12.4556 4.64244 12.3165 4.77962L11.8132 5.28287L9.71845 3.18887L10.2217 2.68487ZM9.1882 3.71987L11.283 5.81387L5.29795 11.7989C5.14491 11.9521 4.95134 12.0585 4.73995 12.1056L2.36995 12.6321L2.90095 10.2449C2.9452 10.0446 3.0457 9.86162 3.19045 9.71687L9.1882 3.71987Z"
                      fill="#474646"
                      fillOpacity="0.5"
                    />
                  </svg>
                </span>{" "}
                Edit
              </Link>
            )}
          {status === TemplateStatus.SCHEDULED && (
            <Link
              className="dropdown-item"
              onClick={() => changePage()}
              to={type ? `${constant.PAGES.HOME.AI_WORKFLOW}/${template.id}` : `${constant.PAGES.HOME.AI_MODEL}/${template.id}`}
            >
              <span className="mr-2">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M12.8857 2.19512C12.6806 1.9794 12.4343 1.80692 12.1615 1.68782C11.8887 1.56872 11.5948 1.50542 11.2971 1.50164C10.9994 1.49787 10.704 1.5537 10.4283 1.66584C10.1525 1.77798 9.902 1.94417 9.69145 2.15462L2.6602 9.18587C2.41444 9.43156 2.24377 9.74219 2.1682 10.0814L1.51045 13.0439C1.49673 13.1054 1.49875 13.1695 1.51634 13.23C1.53393 13.2906 1.56651 13.3458 1.61108 13.3904C1.65564 13.4351 1.71075 13.4677 1.77129 13.4854C1.83183 13.5031 1.89586 13.5052 1.95745 13.4916L4.9027 12.8369C5.25328 12.7588 5.57433 12.5824 5.8282 12.3284L12.8467 5.30987C13.2581 4.8983 13.4924 4.3423 13.4997 3.76043C13.507 3.17856 13.2867 2.61686 12.8857 2.19512ZM10.2217 2.68487C10.3589 2.54571 10.5223 2.43506 10.7024 2.35931C10.8825 2.28356 11.0759 2.2442 11.2713 2.2435C11.4667 2.2428 11.6603 2.28077 11.841 2.35523C12.0217 2.42969 12.1858 2.53916 12.324 2.67734C12.4622 2.81552 12.5716 2.97967 12.6461 3.16034C12.7206 3.34102 12.7585 3.53464 12.7578 3.73005C12.7571 3.92546 12.7178 4.11881 12.642 4.29894C12.5663 4.47907 12.4556 4.64244 12.3165 4.77962L11.8132 5.28287L9.71845 3.18887L10.2217 2.68487ZM9.1882 3.71987L11.283 5.81387L5.29795 11.7989C5.14491 11.9521 4.95134 12.0585 4.73995 12.1056L2.36995 12.6321L2.90095 10.2449C2.9452 10.0446 3.0457 9.86162 3.19045 9.71687L9.1882 3.71987Z"
                    fill="#474646"
                    fillOpacity="0.5"
                  />
                </svg>
              </span>{" "}
              View
            </Link>
          )}
          {status === TemplateStatus.COMPLETED && (
            <Link
              className="dropdown-item"
              onClick={() => changePage()}
              to={type ? `${constant.PAGES.HOME.AI_WORKFLOW}/${template.id}` : `${constant.PAGES.HOME.AI_PREDICTION}/${template.id}`}
            >
              <span className="mr-2">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M12.8857 2.19512C12.6806 1.9794 12.4343 1.80692 12.1615 1.68782C11.8887 1.56872 11.5948 1.50542 11.2971 1.50164C10.9994 1.49787 10.704 1.5537 10.4283 1.66584C10.1525 1.77798 9.902 1.94417 9.69145 2.15462L2.6602 9.18587C2.41444 9.43156 2.24377 9.74219 2.1682 10.0814L1.51045 13.0439C1.49673 13.1054 1.49875 13.1695 1.51634 13.23C1.53393 13.2906 1.56651 13.3458 1.61108 13.3904C1.65564 13.4351 1.71075 13.4677 1.77129 13.4854C1.83183 13.5031 1.89586 13.5052 1.95745 13.4916L4.9027 12.8369C5.25328 12.7588 5.57433 12.5824 5.8282 12.3284L12.8467 5.30987C13.2581 4.8983 13.4924 4.3423 13.4997 3.76043C13.507 3.17856 13.2867 2.61686 12.8857 2.19512ZM10.2217 2.68487C10.3589 2.54571 10.5223 2.43506 10.7024 2.35931C10.8825 2.28356 11.0759 2.2442 11.2713 2.2435C11.4667 2.2428 11.6603 2.28077 11.841 2.35523C12.0217 2.42969 12.1858 2.53916 12.324 2.67734C12.4622 2.81552 12.5716 2.97967 12.6461 3.16034C12.7206 3.34102 12.7585 3.53464 12.7578 3.73005C12.7571 3.92546 12.7178 4.11881 12.642 4.29894C12.5663 4.47907 12.4556 4.64244 12.3165 4.77962L11.8132 5.28287L9.71845 3.18887L10.2217 2.68487ZM9.1882 3.71987L11.283 5.81387L5.29795 11.7989C5.14491 11.9521 4.95134 12.0585 4.73995 12.1056L2.36995 12.6321L2.90095 10.2449C2.9452 10.0446 3.0457 9.86162 3.19045 9.71687L9.1882 3.71987Z"
                    fill="#474646"
                    fillOpacity="0.5"
                  />
                </svg>
              </span>{" "}
              View
            </Link>
          )}
          {!isMobileScreen && (
            <Link className="dropdown-item" onClick={() => duplicateTemplate()}>
              <span className="mr-2">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <g clipPath="url(#clip0_194_777)">
                    <path
                      d="M13.125 4.6875V13.125H4.6875V4.6875H13.125ZM13.125 3.75H4.6875C4.43886 3.75 4.2004 3.84877 4.02459 4.02459C3.84877 4.2004 3.75 4.43886 3.75 4.6875V13.125C3.75 13.3736 3.84877 13.6121 4.02459 13.7879C4.2004 13.9637 4.43886 14.0625 4.6875 14.0625H13.125C13.3736 14.0625 13.6121 13.9637 13.7879 13.7879C13.9637 13.6121 14.0625 13.3736 14.0625 13.125V4.6875C14.0625 4.43886 13.9637 4.2004 13.7879 4.02459C13.6121 3.84877 13.3736 3.75 13.125 3.75Z"
                      fill="#474646"
                      fillOpacity="0.5"
                    />
                    <path
                      d="M1.875 8.4375H0.9375V1.875C0.9375 1.62636 1.03627 1.3879 1.21209 1.21209C1.3879 1.03627 1.62636 0.9375 1.875 0.9375H8.4375V1.875H1.875V8.4375Z"
                      fill="#474646"
                      fillOpacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_194_777">
                      <rect width="15" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>{" "}
              Copy / Duplicate
            </Link>
          )}

          {(status === TemplateStatus.NEW ||
            status === TemplateStatus.DRAFT) && (
              <Link
                className="dropdown-item"
                onClick={() => deleteTemplate(template)}
              >
                <span className="mr-2">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <g clipPath="url(#clip0_194_780)">
                      <path
                        d="M4.5 3V1.5C4.5 1.23478 4.60536 0.98043 4.79289 0.792893C4.98043 0.605357 5.23478 0.5 5.5 0.5H9.5C9.76522 0.5 10.0196 0.605357 10.2071 0.792893C10.3946 0.98043 10.5 1.23478 10.5 1.5V3M0 3.5H15M1.5 3.5V13.5C1.5 13.7652 1.60536 14.0196 1.79289 14.2071C1.98043 14.3946 2.23478 14.5 2.5 14.5H12.5C12.7652 14.5 13.0196 14.3946 13.2071 14.2071C13.3946 14.0196 13.5 13.7652 13.5 13.5V3.5M7.5 7V12M4.5 9V12M10.5 9V12"
                        stroke="#474646"
                        strokeOpacity="0.5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_194_780">
                        <rect width="15" height="15" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                Delete
              </Link>
            )}
        </div>
      </div>
    );
  };

  const renderRecentModelItem = () => {
    const { template_name, status, url, id, updatedAt, createdAt, name } = template;
    return (
      <div className="col-md-12 col-lg-6 col-xl-4 mt-4 mb-3">
        <div className="recentmodelbox">
          <div className="d-flex pl-3 align-items-center border-bottom justify-content-between">
            <div>
              <b>Name : </b> {template_name || name}
            </div>
            <div className="p-3 mr-1">{renderMoreOptions(status, id)}</div>
          </div>
          <div className="mt-3 mb-5 align-self-center">
            <img
              src={url ? url : images.draftImage}
              style={url ? { height: 200 } : { transform: "scale(2)" }}
              className="recent-model-img"
              alt={template_name}
            />
          </div>
          <div className={`modelupdatebox p-3 d-flex border-top mt-auto ${type ? 'justify-content-end' : 'justify-content-between'}`}>
            {(createdAt && updatedAt) &&
              <div className="text-secondary">
                <div>
                  Created: {formatDate(createdAt, constant.dateTimeFormat)}
                </div>
                <div>
                  Modified: {formatDate(updatedAt, constant.dateTimeFormat)}
                </div>
              </div>
            }
            {renderViewDetails(status)}
          </div>
        </div>
      </div>
    );
  };
  return render();
};

export default RecentModelItem;
